import React, { PureComponent } from "react";
import styled from "styled-components";

import AnimatedGrapl from "../../../assets/loaders/Grapl-Icon-Animation-250.gif";

const MAX_PROGRESS = 100;

export default class Loading extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			progress: 0
		};
	}

	componentDidMount() {
		this.interval = setInterval(() => {
			if (this.state.progress === MAX_PROGRESS) {
				clearInterval(this.interval);
			} else {
				this.setState((prevState) => ({
					...prevState,
					progress: this.state.progress + 1,
				}));
			}
		}, 80);
	}

	componentWillUnmount() {
		clearInterval(this.interval);
	}

	render() {
		return (
			<Container>
				<LoaderContainer>
					<LoaderImage src={AnimatedGrapl} />
					{/* <ProgressBar progress={this.state.progress} /> */}
				</LoaderContainer>
			</Container>
		);
	}
}

const Container = styled.div`
	background-color: transparent;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	width: 100%;
	height: 100%;
`;

const Text = styled.div`
	text-align: center;
	font-size: 20px;
	color: white;
	margin-top: 16px;
`;

const LoaderContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const LoaderImage = styled.img`
	border-radius: 50%;
	padding: 4px;
	width: 128px;
	height: 128px;
	margin-bottom: 16px;
`;

const ProgressBar = styled.div`
	position: relative;
	margin-bottom: 8px;
	width: 256px;
	height: 8px;
	border-radius: 32px;
	background-color: #fff;
	overflow: hidden;
	box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);

	&:before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		background: #4042e2;
		background: linear-gradient(
			90deg,
			#f69018 0%,
			#4042e2 100%
		);
		width: ${(props) => props.progress}%;
	}
`;
