import React from 'react';

const RedirectToRoom = () => {
    React.useEffect(() => {
        window.location.href = `/${localStorage.getItem('lastMeeting')}`;
    }, []);

    return <></>;
};

export default RedirectToRoom;
