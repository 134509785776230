import React from 'react'
import Reflux from 'reflux'
import styled from 'styled-components'

import { AuthStore, AuthActions } from '/stores/AuthStore'
import Modal from '/components/Modalv2'
import AllWidgets from '../../../loop-widgets'
import { ModalActions } from '../../../stores/ModalStore'

export default class Integrations extends Reflux.Component {
	constructor(props) {
		super(props)
		this.store = AuthStore
		this.storeKeys = ['external_tokens']
	}
	startLogin(key) {
		window.open(
			(process.env.REACT_APP_API_URL || 'http://localhost:8000') +
				`/oauth/auth/${key}`
		)
	}
	render() {
		const oauth_widgets = Object.values(AllWidgets)
			.filter(widget => widget.widgetConfig.oauthType)
			.map(w => w.widgetConfig)

		const mapped_integrations = oauth_widgets.map(w => (
			<Integration key={w.key}>
				<Logo src={w.icon} alt="" /> <div className="label">{w.displayName}</div>{' '}
				{this.state.external_tokens[w.key] ? (
					<div className="button link" onClick={() => ModalActions.SetModalWithParams('RemoveIntegration', { syncName: w.displayName,cb: () => AuthActions.RemoveExternalToken(w.key) })}>
						<div className="buttonlabel">Remove</div>
					</div>
				) : (
					<div className="button primary" onClick={() => this.startLogin(w.oauthKey || w.key)}>
						Integrate
					</div>
				)}
			</Integration>
		))
		return (
			<Modal closeModal={this.props.closeModal}>
				<Head>Your Integrations</Head>
				<Container>{mapped_integrations}</Container>
			</Modal>
		)
	}
}

const Head = styled.div`
	margin-top: 30px;
	text-align: center;
	font-size: 16px;
	font-weight: 500;
	min-width: 350px;
`

const Integration = styled.div`
	display: flex;
	align-items: center;
	margin: 6px;
	font-size: 16px;

	> div.label {
		margin-right: 16px;
	}

	> .button {
		padding: 10px 12px !important;
		font-size: 14px;
		margin-left: auto;
		cursor: pointer;

		.buttonlabel {
			border-bottom: 1px solid;
			padding-bottom: 1px;
			font-weight: 500;
		}
	}

	> button {
		margin-left: auto;
		border: none;
		box-shadow: ${props => props.theme.shadows.light};
		cursor: pointer;
		&:hover {
			box-shadow: ${props => props.theme.shadows.heavy};
		}
	}
`

const Logo = styled.img`
	width: 24px;
	margin-right: 15px;
`

const Container = styled.div`
	position: relative;
	text-align: center;
	padding: 30px;
	display: flex;
	align-items: stretch;
	flex-direction: column;
`
