import React, { useState, useEffect, memo } from "react";
import Reflux from "reflux";
import styled from "styled-components";
import { pollAudioLevel } from "/calling/_helpers.tsx";

const TrackWaveMeter = props => {
  const [ decibals, setDecibals ] = useState(0)
  let pollAudio = null

  useEffect(() => {
    const init = async () => {
      if (!props.track) {
        return null
      }
      pollAudio = await pollAudioLevel(props.track, onLevelChanged);
    }

    init()

    return () => {
      if (pollAudio && pollAudio.stop) {
        pollAudio.stop()
      }
    }
  }, [props.track])

  const onLevelChanged = (level) => {
    setDecibals((level * 10) - 110)
  };

  // shouldComponentUpdate(nextProps, nextState) {
  //   return JSON.stringify(nextProps.track) !== JSON.stringify(this.props.track) && nextState.level !== this.state.level
  // }

  // //console.log("WAVEMETER: ", this.state.peerDecibals, this.props)
  // Tons of magic numbers here :D
  // Decibals go between -100 and 0, zero is loudest
  // microphone can handle. 50 is the threshhold to
  // activate.
  // if(decibals < -50) return <div></div>

  // level = 0 to 10
  // decibals = -100 to 0

  const scale1 = decibals && decibals > -50 ? (50 + decibals) * 1 + 20 : 20;
  const scale2 = decibals && decibals > -50 ? (50 + decibals) * 2 + 45 : 20;
  const scale3 = decibals && decibals > -50 ? (50 + decibals) * 3 + 75 : 20;

  return (
    <WaveContainer className="">
      <Wave className="box" scale={Math.min(Math.max(scale1, 20), 30)}></Wave>
      <Wave className="box" scale={Math.min(Math.max(scale2, 20), 55)}></Wave>
      <Wave className="box" scale={Math.min(Math.max(scale3, 20), 75)}></Wave>
      <Wave className="box" scale={Math.min(Math.max(scale2, 20), 55)}></Wave>
      <Wave className="box" scale={Math.min(Math.max(scale1, 20), 30)}></Wave>
    </WaveContainer>
  )
    
}

const Wave = styled.div`
  height: ${(props) => props.scale}% !important;
`;

const WaveContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  --boxSize: 4px;
  --gutter: 2px;
  min-width: 30px;
  width: calc((var(--boxSize) + var(--gutter)) * 5);

  .box {
    height: 20%;
    width: 4px;
    margin: 0 0.5px;
    /* background: linear-gradient(180deg, #213465 0%, #008BFF 100%); */
    background: #ff9900;
    border-radius: 30px;
    transition: 0.2s height ease-in-out;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.4);
  }
`;

function areEqual(prevProps, nextProps) {
  return JSON.stringify(prevProps.track) === JSON.stringify(nextProps.track)
}

export default memo(TrackWaveMeter, areEqual)