import React, { Component } from 'react'
import deepEqual from 'fast-deep-equal'
import styled from 'styled-components'
import WidgetSetup from './WidgetSetup'
/*
  This is a higher order component that remaps the data passed to the widget

  Since we're passing pretty much all of the meeting data this would cause
  every widget to rerender every time any meeting data changes. This HOC
  uses the static widgetConfig variable on widgets. Here is the schema:

  {
	key: '', // Key used in the widget store
	displayName: '', // Widget name shown to users
	widgetDeps: [], // List of widgets that will be also added if this widget is added. Doesn't work yet
	dataUsed: {
	  transcription: boolean, // Transcribed recordings of streams
	  users: boolean, // Whether or not to pass the data of the users of the meeting
	  otherWidgets: [] // Data of other widgets that should be passed. Don't list THIS widget here, that data is passed either way
	}
  }
*/
export default class WidgetRenderer extends Component {
	constructor(props) {
		super(props)

		this.state = {
			hasError: false,
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (
			this.state.hasError &&
			!deepEqual(
				nextProps.widgets[this.props.stateKey],
				this.props.widgets[this.props.stateKey]
			)
		) {
			this.setState({ hasError: false })
		}
	}

	shouldComponentUpdate(nextProps, nextState) {
		const config = nextProps.RenderedWidget.widgetConfig
		const dataUsed = config.dataUsed || {}
		return !!(
			nextProps.bot_mode !== this.props.bot_mode ||
			nextProps.preview !== this.props.preview ||
			nextProps.maximized !== this.props.maximized ||
			nextProps.external_token !== this.props.external_token ||
			nextProps.meetingName !== this.props.meetingName ||
			nextProps.actions !== this.props.actions ||
			nextProps.RenderedWidget !== this.props.RenderedWidget ||
			nextProps.userId !== this.props.userId ||
			!deepEqual(
				nextProps.widgets[this.props.stateKey],
				this.props.widgets[this.props.stateKey]
			) ||
			(dataUsed.users && nextProps.users !== this.props.users) ||
			(dataUsed.files && nextProps.files !== this.props.files) ||
			(dataUsed.transcription &&
				nextProps.transcription !== this.props.transcription) ||
			(dataUsed.otherWidgets &&
				!deepEqual(
					_consolidateWidgetData(dataUsed.otherWidgets, nextProps.widgets),
					_consolidateWidgetData(dataUsed.otherWidgets, this.props.widgets)
				)) ||
			(dataUsed.color_scheme && nextProps.color_scheme !== this.props.color_scheme)
		)
	}

	componentDidCatch(error, info) {
		// Display fallback UI
		this.setState({ hasError: true })
		console.error(error)
	}

	render() {
		if (this.state.hasError) {
			return (
				<Container className="widgetRender">
					Uh oh something went wrong
				</Container>
			)
		}

		const config = this.props.RenderedWidget.widgetConfig
		const RenderedWidget = this.props.RenderedWidget.render

		// Creating actions with injected method
		const FilledActions = {
			...this.props.actions,
			// If 'UpdateWidget' is passed also pass an 'UpdateSelf' method that injects the
			// widget key automatically
			UpdateSelf: async (diff, callback) => {
				await this.props.actions.UpdateWidget({ name: this.props.stateKey, ...diff })
				if (callback) callback()
			},
			SendNotification: (body, target_user_id) =>
				this.props.actions.SendNotification({
					body,
					target_user_id,
					widget: config.displayName,
				}),
		}
		if (
			config.requiredSetup &&
			this.props.widgets[this.props.stateKey]._requiresSetup === true
		) {
			return (
				<Container className="widgetRender">
					<WidgetSetup
						fields={config.requiredSetup}
						setupComplete={FilledActions.UpdateSelf}
					/>
				</Container>
			)
		}

		const defaultProps = this.props.RenderedWidget.widgetConfig.defaultProps
			? this.props.RenderedWidget.widgetConfig.defaultProps.data
			: {}

		// Fill up the props using the dataUsed config object
		const dataUsed = config.dataUsed || {}
		let props = {
			bot_mode: this.props.bot_mode,
            travelling: this.props.travelling,
			preview: this.props.preview,
			maximized: this.props.maximized,
			actions: FilledActions,
			data: {
				...defaultProps,
				...this.props.widgets[this.props.stateKey],
			},
			external_token: this.props.external_token,
			users: dataUsed.users && this.props.users,
			files: dataUsed.files && this.props.files,
			userId: this.props.userId,
			meetingName: this.props.meetingName,
			transcription: dataUsed.transcription && this.props.transcription,
			otherWidgetData:
				dataUsed.otherWidgets &&
				_consolidateWidgetData(dataUsed.otherWidgets, this.props.widgets),
			...(dataUsed.color_scheme ? { color_scheme: dataUsed.color_scheme && this.props.color_scheme } : {})
		}

		return (
			<Container className="widgetRender">
				<RenderedWidget {...props} />
			</Container>
		)
	}
}

// Private function for iterating over meeting's widgets
// and injecting them into passed props
const _consolidateWidgetData = (widgetList, widgets) => {
	let otherWidgetData = {}

	for (let i = 0; i < widgetList.length; i++) {
		const w = widgetList[i]
		otherWidgetData[w] = widgets[w]
	}

	return otherWidgetData
}

const Container = styled.div`
	position: relative;
	flex: 1;
	top: 0;
	overflow: auto;
	display: flex;

	&.widgetRender {
		width: 100%;
		flex-wrap: wrap;
		overflow: overlay;
		overflow-x: hidden;

		> div {
			width: 100%;
			flex: unset;
		}
	}
`
