import React from 'react'
import styled from 'styled-components'
import {Img} from 'react-image'
import VisibilitySensor from 'react-visibility-sensor'
import Grapl from '../../../assets/Icon-SVG.svg'

import Loader from '../../../components/Loader'
import CustomTip from '../../../helpers/CustomTip'

import UserInCallItem from '../Sidebar/UsersInCall/UserInCallItem'

const IconSvg = <img src={Grapl} alt="Grapl"/>

const KeynoteUserItem = ({ user, audioTrack }) => (
	<UserContainer className='' key={user.username || user.name || ''} data-tip data-for={user.username || user.name || ''}>

		<VisibilitySensor>
			<Img 
				key={Math.random()}
				src={[user.avatar_url, `https://ui-avatars.com/api/?name=${user.username || user.name || ''}&format=svg&background=${user.color || 'random'}&color=fefefe`, 'https://grapl-storage.s3.ap-southeast-1.amazonaws.com/Grapl.svg']} 
				loader={<Loader containerHeight="42px" containerWidth="42px" circle/>} 
				unloader={IconSvg} 
				alt={user.username || user.name || ''} 
				className="border-topbar container content"
			/>
		</VisibilitySensor>
		{/* <Username>{user.name}</Username> */}
		<CustomTip top={-40} left={-25} class='top-right' place='left' tip={user.username || user.name || ''} dataFor={user.username || user.name || ''} />

		{audioTrack && <UserInCallItem audioTrack={audioTrack} id={user.id} />}

	</UserContainer>
)

const Username = styled.div`
	transition: opacity 0.3s ease-out;
	opacity: 0;
	pointer-events: none;
	position: absolute;
	bottom: -12px;
	background-color: white;
	color: #333;
	padding: 6px;
	border-radius: 4px;

	&:before {
		width: 0;
		height: 0;
		border-left: 6px solid transparent;
		border-right: 6px solid transparent;

		border-bottom: 5px solid white;
		position: absolute;
		top: 1px;
		left: Calc(50% - 6px);
		content: '';
	}
`

const UserContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	padding: 6px;
	cursor: pointer;
	&:hover ${Username} {
		opacity: 1;
	}
	width: fit-content;
	height: fit-content;

	&:hover {
		z-index: 1;
	}
	img {
		width: 42px;
		height: 42px;
		border-radius: 9999px;
		border: 2px solid;
	}
`

export default KeynoteUserItem
