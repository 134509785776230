import React from 'react'
import Reflux from 'reflux'
import { MainStore, MainActions } from '/stores/MainStore'
import Modal from '/components/Modal'
import styled from 'styled-components'
import LoopApi from '/helpers/LoopApi'
import { ThemingStore } from '../../../stores/ThemingStore'

export default class AddFromTeam extends Reflux.Component {
  constructor(props) {
    super(props)

    this.state = {
      db_meeting: {},
      users: [],
      fetchedUsers: [],
    }
    this.stores = [MainStore, ThemingStore]
    this.storeKeys = ['db_meeting', 'users', 'color_scheme']
  }

  componentDidMount() {
    LoopApi(null, 'GetTeamMembers', {}, [
      ['team_id', this.state.db_meeting.team_id],
    ])
      .then(fetchedUsers => {
        this.setState({ fetchedUsers })
      })
      .catch(e => console.error(e))
  }

  async addToMeeting(user) {
    try {
      const resp = await LoopApi(
        null,
        'AddUserToMeeting',
        {
          user_id: user._id,
        },
        [['name', this.state.db_meeting.name]]
      )
      let dbMeeting = resp.dbMeeting

			if(dbMeeting && dbMeeting.settings && dbMeeting.settings.color_scheme) {
				dbMeeting = {
					...dbMeeting,
					settings: {
						...dbMeeting.settings,
						color_scheme: localStorage.getItem('color_scheme') || this.state.color_scheme || dbMeeting.settings.color_scheme
					}
				}
			}
      MainActions.DbMeeting(dbMeeting)
    } catch (e) {
      console.error(e)
    }
  }

  render() {
    const users = this.state.fetchedUsers
      // Comment this line out for testing 
      .filter(
        u =>
          !this.state.users.find(meeting_user => meeting_user.id === u._id) &&
          !this.state.db_meeting.user_ids.find(us => us === u._id)
      )
      .map(u => (
        <MemberItem key={u._id}>
          <Avatar src={u.avatar_url} />
          <div>{u.username}</div>
          <button onClick={this.addToMeeting.bind(this, u)}>Add</button>
        </MemberItem>
      ))
    return (
      <Modal closeModal={this.props.closeModal}>
        <HeaderContainer>
          <Title>Add Team Members</Title>
          <SubTitle>Team members who aren't in the meeting:</SubTitle>
        </HeaderContainer>
        <Container>
          <MemberContainer>{users}</MemberContainer>
        </Container>
      </Modal>
    )
  }
}

const Avatar = styled.img`
  height: 32px;
  width: 32px;
  border-radius: 4px;
  margin-right: 12px;
`

const MemberItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 6px 0;

  > button {
    margin-left: auto;
  }
`

const HeaderContainer = styled.div`
  min-width: 360px;
  padding: 30px 14px;
  background-color: ${props => props.theme.bgPrimaryDark};
  border-top-right-radius: ${props => props.theme.borderRadius};
  border-top-left-radius: ${props => props.theme.borderRadius};
  border-bottom: 1px solid ${props => props.theme.borderPrimary};
`

const Title = styled.h1`
  text-align: center;
  font-size: ${props => props.theme.textXL};
  font-weight: ${props => props.theme.textRegular};
  color: ${props => props.theme.textPrimary};
`

const SubTitle = styled.h1`
  padding-top: 6px;
  text-align: center;
  font-size: ${props => props.theme.textMD};
  font-weight: ${props => props.theme.textRegular};
  color: ${props => props.theme.textSecondary};
`

const Container = styled.div`
  max-height: 350px;
  overflow-y: scroll;
`

const MemberContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px 30px;
`
