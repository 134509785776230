import React from 'react'
import { ThemeProvider } from 'styled-components'
import Reflux from 'reflux'

import { ThemingStore, ThemingActions } from './stores/ThemingStore'
import { MainStore } from '/stores/MainStore'
import ThemeStyles from './ThemeStyles'

class ThemeInner extends React.Component {
	constructor(props) {
		super(props)
	}
	render() {
		return (
			<ThemeProvider theme={BuildTheme(this.props.theme)}>
				{this.props.children}
			</ThemeProvider>
		)
	}

}

const ThemeController = (props) => {
	React.useEffect(() => {
		window.addEventListener('resize', ThemingActions.Resize)
		return () => window.removeEventListener('resize', ThemingActions.Resize)
	})

	return <ThemeProvider theme={BuildTheme(props.theme)}>
	{props.children}
</ThemeProvider>
}
// 	constructor(props) {
// 		super(props)
// 		this.stores = [ThemingStore, MainStore]
// 		this.storeKeys = [
// 			'mobile',
// 			'rowCount',
// 			'color',
// 			'bot_mode',
// 			'presentation',
// 			'db_meeting',
// 		]
// 	}
// 	componentWillMount() {
// 		window.addEventListener('resize', ThemingActions.Resize)
// 		super.componentWillMount() // When using a Reflux component this must be included
// 	}

// 	componentDidMount() {
// 		ThemingActions.Resize()
// 	}
// 	componentWillUnmount() {
// 		window.removeEventListener('resize', ThemingActions.Resize)
// 		super.componentWillUnmount() // When using a Reflux component this must be included
// 	}
// 	render() {
// 		return (
// 			<ThemeInner theme={this.state}>
// 				{this.props.children}
// 			</ThemeInner>
// 		)
// 	}
// }

function BuildTheme(themeState) {
	return Object.assign({}, ThemeStyles, {
		// ...themeState,
		// db_meeting: undefined,
		// presentation: {
		// 	...((themeState.db_meeting && themeState.db_meeting.bot_settings) || {}),
		// 	...themeState.presentation,
		// 	flow_type: themeState.db_meeting.flow_type,
		// },
	})
}

export default ThemeController
