import React from 'react'

import ChatSelf from './ChatSelf'
import ChatOther from './ChatOther'

const getReadableTimestamp = (timestamp) => {
    const date = new Date(timestamp)
    return date.toLocaleString()
}

const ChatMapper = (chats, users = [], userId, setLightBoxPhotos, onDownload) => {


    return chats.map((c, ii) => {
        const isPrevChatFromSameUser =
            c.userId === (chats[ii - 1] && chats[ii - 1].userId)
        const skipBreak =
            isPrevChatFromSameUser &&
            c.timeStamp - chats[ii - 1].timeStamp < 1000 * 60

        const passedProps = {
            chat: c,
            userInfo: users.find((u) => u.id === c.userId) || {},
            key: ii,
            timestamp: getReadableTimestamp(c.timeStamp),
            skipBreak,
            setLightBoxPhotos,
            onDownload
        }

        if (c.userId === userId) {
            return <ChatSelf {...passedProps} />
        } else {
            return <ChatOther {...passedProps} />
        }
    })
}

export default ChatMapper
