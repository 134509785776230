import React, { memo } from 'react';
import styled from 'styled-components';

import Modal from '/components/Modalv2';
const { GlobalState } = require('reflux');
import check from '../../../../src/assets/icons/check.svg';
import checkLight from '../../../../src/assets/icons/check-light.svg';
import LoopApi from '../../../helpers/LoopApi';
import exclamationIcon from '../../../assets/icons/exclamation.svg'
import moment from 'moment'
import { ModalActions } from '/stores/ModalStore';

const SubscriptionCancelFeedback = memo((props) => {
    const [feedbacks, setFeedbacks] = React.useState([])
    const [other, setOther] = React.useState(null)
    const [otherCheck, setOtherCheck] = React.useState(false)

    const handleChange = (e) => {
        if(e.target.checked) {
            setFeedbacks([...feedbacks, e.target.name])
        } else {
            var filtered = feedbacks.filter(f => f !== e.target.name) 
            setFeedbacks(filtered);
        }
    }

    const handleOther = (e) => {
        setOther(e.target.value)
    }

    const handleOtherCheck = (e) => {
        setOtherCheck(e.target.checked)    
    }

    const handleSubmit = async () => {
        let fb = otherCheck ? [...feedbacks, other] : feedbacks;
        const data = await LoopApi(null, 'StripeCancelSubscription', { room: GlobalState.modal.room, feedbacks: fb });
        localStorage.setItem('token', data.token);
        if(GlobalState.main.db_meeting._id === GlobalState.modal.room) {
            props.closeModal();
            window.location.reload();
        } else {
            props.closeModal();
            window.location.href = "/create-or-join";
        }
    }

    return (
        <Modal closeModal={props.closeModal} dismissible={true}>
            <Container className='topbar'>
                <div className='head-1'>Help us understand the reason</div>
                <div className='head-2'>
                   We're sorry to see you go!
                </div>
                <div className='description'>
                   <input type="checkbox" name="Technical Issues" onChange={handleChange} /> Technical Issues
                </div>
              
                <div className='description'>
                   <input type="checkbox" name="Too Expensive" onChange={handleChange} /> Too Expensive
                </div>
              
              
                <div className='description'>
                   <input type="checkbox" name="Switching to another product" onChange={handleChange} /> Switching to another product
                </div>
              
              
                <div className='description'>
                   <input type="checkbox" name="Shutting down the company" onChange={handleChange} /> Shutting down the company
                </div>
              
              
                <div className='description'>
                   <input type="checkbox" name="Not sure how to use the tools " onChange={handleChange} /> Not sure how to use the tools 
                </div>
              
              
                <div className='description'>
                   <input type="checkbox" name="Missing features I need" onChange={handleChange} /> Missing features I need
                </div>
              
              
                <div className='description'>
                   <input type="checkbox" name="Others" onChange={handleOtherCheck}/> Others (please explain below)
                </div>
              
                <div className='description-100'>
                    <textarea type="text" rows="4" onChange={handleOther} className="topbar" />
                </div>
              
              
                <div className='center-btn'>
                    <button className='big-btn topbar' onClick={() => ModalActions.SetModalWithParams('CancelSubscription')}>Undo</button>
                    <button className='big-btn-disable' onClick={handleSubmit}>Cancel Subscription</button>
                </div>
            </Container>
        </Modal>
    );
});

export default SubscriptionCancelFeedback;

const Container = styled.div`
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    max-width: 600px;

    .head-1 {
        font-size: 1.6em;
        margin-bottom: 10px;
    }

    .head-2 {
        margin-bottom: 20px;
        line-height: 1.2em;
    }

    .description {
        display: flex;
        align-items: center;
        line-height: 2em;
    }

    .description-100 {
        display: flex;
        align-items: center;
        line-height: 2em;
        width: 100%;

        textarea {
            width: 100%
        }
    }

    .select {
        select {
            border-radius: 15px;
            width: 200px;
            height: 35px;
            border: 1px solid;
            margin-left: 20px;
        }
    }

    .center-btn {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;

        .big-btn {
            height: 40px;
            width: 215px;
            margin-top: 20px;
            margin-bottom: 10px;
            cursor: pointer;
            margin-left: 5px;
            border: 1px solid #E40707;
            box-sizing: border-box;
            border-radius: 999px;
            color: red;
        }

        .big-btn-disable {
            width: 215px;
            height: 40px;
            background: #ED3030;
            border-radius: 999px;
            width: 215px;
            color: white;
            border: 0;
            margin-top: 20px;
            margin-bottom: 10px;
            cursor: pointer;
            margin-left: 5px;
        }

        .link-btn {
            cursor: pointer;
        }
    }
`;
