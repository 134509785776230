import React, { PureComponent } from 'react'
import styled from 'styled-components'

import ArrowUpIcon from '../../../assets/icons/arrow_up.svg'
import Modal from '/components/Modal'

export default class EnableMedia extends PureComponent {
	render() {
		return (
			<Modal closeModal={this.props.closeModal}>
				<Container>
					<ArrowUp />
					<Title>The browser is blocking your microphone.</Title>
					<EnableImg alt="Enable Microphone" src="/enable_microphone.png" />
					<div>
						Click the camera on the right side of your address bar and then
						select<Quote>
							Always allow https://www.blocktalk.com to access your microphone
						</Quote>
					</div>
				</Container>
			</Modal>
		)
	}
}

const ArrowUp = styled.img.attrs({ src: ArrowUpIcon })`
	position: absolute;
	top: -120px;
	width: 120px;
`

const EnableImg = styled.img`
	width: 88px;
	margin: 17px;
	box-shadow: 0 2px 4px 1px rgba(0, 0, 200, 0.3);
`

const Container = styled.div`
	position: relative;
	text-align: center;
	padding: 20px;
	display: flex;
	align-items: center;
	flex-direction: column;
`
const Title = styled.div`
	font-size: 16px;
	margin-bottom: 6px;
	font-weight: 500;
`

const Quote = styled.div`
	margin-top: 6px;
	font-size: 14px;
	background: rgba(0, 0, 0, 0.05);
	padding: 8px;
	border-radius: 4px;
	border: 1px solid rgba(0, 0, 0, 0.2);
`
