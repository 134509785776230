import React, { createElement } from 'react'
import Reflux from 'reflux'
import styled from 'styled-components'
import moment from 'moment'
import ReactQuill from 'react-quill'
import { renderToStaticMarkup } from "react-dom/server";

import { FiChevronLeft as BackIcon } from '@react-icons/all-files/fi/FiChevronLeft'
import { FiMoreHorizontal as MoreOptionsIcon } from '@react-icons/all-files/fi/FiMoreHorizontal'
import { FaLock as LockedIcon } from '@react-icons/all-files/fa/FaLock'
import { MdFormatBold as BoldIcon } from '@react-icons/all-files/md/MdFormatBold'
import { MdFormatItalic as ItalicIcon } from '@react-icons/all-files/md/MdFormatItalic'
import { MdFormatUnderlined as UnderlineIcon } from '@react-icons/all-files/md/MdFormatUnderlined'
import { MdStrikethroughS as StrikeIcon } from '@react-icons/all-files/md/MdStrikethroughS'
import { HiCode as CodeIcon } from '@react-icons/all-files/hi/HiCode'
import { MdFormatQuote as QuoteIcon } from '@react-icons/all-files/md/MdFormatQuote'
import { GoListOrdered as OrderedIcon } from '@react-icons/all-files/go/GoListOrdered'
import { GoListUnordered as UnorderedIcon } from '@react-icons/all-files/go/GoListUnordered'
import { HiLink as LinkIcon } from '@react-icons/all-files/hi/HiLink'
import { FiX as CloseIcon } from '@react-icons/all-files/fi/FiX'

import { MainStore } from '/stores/MainStore'
import { ScrollView } from '/components/Elements'
import Modal from '/components/Modalv2'
import DeleteLinks from './DeleteLinks'
import DropdownWrapper from '/screens/Meeting2.0/Sidebar/TopBar/DropdownWrapper'

import 'react-quill/dist/quill.snow.css'
import { ThemingStore } from '../../../../../stores/ThemingStore'
import NotesEditor from './NotesEditor'
const deepCopyString = (str) => (' ' + str).slice(1)
const icons = ReactQuill.Quill.import('ui/icons')
icons.bold = BoldIcon
icons.italic = ItalicIcon
icons.underline = null
icons.strike = null
icons['code-block'] = null
icons.blockquote = null
icons.color = null
icons.list = null
icons.link = null
class NotesContent extends Reflux.Component {
    constructor(props) {
        super(props)

        this.stores = [MainStore, ThemingStore]
        this.storeKeys = ['widgets', 'users', 'color_scheme']

        this.editor = null

        this.state = {
            widgets: {
                notes: { notes: []}
            },
            color_scheme: 'Light'
        }

        this.modules = {
            toolbar: {
                container: `#toolbarmini${props.activeNote || ''}`,
            },
        }

        this.handleContentUpdate = this.handleContentUpdate.bind(this)
        this.handleTitleUpdate = this.handleTitleUpdate.bind(this)
    }

    shouldComponentUpdate(nextProps, nextState) {
        const { notes = {} } = this.state.widgets
        const { activeNote } = this.props;
        const { notes: nextNotes = {} } = nextState.widgets

        const active = (notes.notes || []).find((note) => note.id === activeNote)
        const nextActive = (nextNotes.notes || []).find((note) => note.id === activeNote)
        return JSON.stringify(active) !== JSON.stringify(nextActive)
    }

    handleContentUpdate(id, content, pureText,) {
        const { notes = {} } = this.state.widgets
        const date_updated = moment().format()

        const myUser =
            this.state.users.find(
                u => u.id === Reflux.GlobalState.auth.jwt.data._id
            ) || {}

        const currentNote = (notes.notes || []).find((note) => note.id === id)
        const otherNotes = (notes.notes || []).filter((note) => note.id !== id)

        const data = {
            ...currentNote,
            date_updated,
            updated_by: myUser.id || null,
            content,
            pureText: pureText || ''
        }
        const newNotes = [data, ...otherNotes]
        this.props._updateSelf({
            notes: newNotes,
            updatedFrom: 'floating'
            // active: id
        })
    }

    handleTitleUpdate(id, e) {
        const { notes = {} } = this.state.widgets
        const date_updated = moment().format()
        const currentNote = (notes.notes || []).find((note) => note.id === id)
        const otherNotes = (notes.notes || []).filter((note) => note.id !== id)
        const myUser =
			this.state.users.find(
				u => u.id === Reflux.GlobalState.auth.jwt.data._id
			) || {}


        const data = {
            ...currentNote,
            date_updated,
            updated_by: myUser.id || null,
            title: e.target.value,
        }

        const newNotes = [data, ...otherNotes]

        this.props._updateSelf({
            notes: newNotes,
        })
    }


    render() {
        const { notes = {} } = this.state.widgets
        const { activeNote, updating, transcribing, updatedFrom } = this.props;
        const active = (notes.notes || []).find((note) => note.id === activeNote)
        const activeIndex = (notes.notes || []).findIndex((note) => note.id === activeNote)
        const val = deepCopyString(active && active.content ? active.content : '')
            .replace(/&nbsp/g, ' ')
            .replace(/<br\/>/g, '\n')

        return (
            <NotesContainer>
                <Header className="border-light">
                    <HeaderLeft>
                        <BackIcon className="btn" size={20} onClick={() => this.props._setActiveNote(null)}/>
                        <input type="text" value={active.title} placeholder='New note' className="topbar" onChange={(e) => this.handleTitleUpdate((active.id), e)} />
                        {active.private ? <span><LockedIcon size={10} title="Private note" /></span> : ''}
                    </HeaderLeft>
                    <HeaderRight>
                        <Dropdown DropdownItems={this.props.DropdownContent} active={activeNote} idx={activeIndex}>
                            <MoreOptionsIcon size={20} />
                        </Dropdown>
                        <CloseIcon size={20} onClick={() => this.props._updateActiveSync(null)} />
                    </HeaderRight>
                </Header>
                <Body>
                <Scroll color={this.state.color_scheme === 'Light' ? '#F89809' : '#008BFF'} defColor={this.state.color_scheme === 'Light' ? '#363B45' : '#FFFFFF'} bgColor={this.state.color_scheme === 'Light' ? '#FFFFFF' : '#272732'}>
                    <div data-text-editor="namemini" style={{ height: '100%' }}>
                        {/* <ReactQuill
                            id={`mininote`}
                            theme="snow"
                            ref={(el) => this.editor = el}
                            defaultValue={val || ''}
                            placeholder="Start taking notes!"
                            value={val || ''}
                            onChange={(content, delta, source, editor) => this.handleContentUpdate(active.id, content, delta, source, editor)}
                            modules={{
                                toolbar: [['bold', 'italic', 'underline', 'strike', 'code-block', 'blockquote',{ 'list': 'ordered'}, { 'list': 'bullet' }, 'link']]
                            }}
                            formats={NotesContent.formats}
                            bounds={`[data-text-editor="namemini"]`}
                        /> */}
                        <NotesEditor activeData={active} handleContentUpdate={this.handleContentUpdate} updateSelf={this.props._updateSelf} updating={updating} updatedFrom={updatedFrom} transcribing={transcribing} addLink={this.props.addLink}/>
                    </div>
                    </Scroll>
                </Body>
                {
                    this.state.deleteOpen ?
                    <Modal show={this.state.deleteOpen} overlayClicked={(e) => this._handleOpenDelete(null, e)}>
                        <DeleteLinks
                            {...this.props}
                            handleClose={this._handleOpenDelete}
                            handleSubmit={(e) => {
                                this._handleDeleteNote(this.state.deleteData, e)
                                this._handleOpenDelete(null, e)
                            }}
                        />
                    </Modal> : ''
                }
            </NotesContainer>
        )
    }
}

const Dropdown = styled(DropdownWrapper)`
    display: flex;
    justify-content: center;
    align-items: center;
	cursor: pointer;
	margin: 4px 5px;
	position: relative;

    .dropdown-content {
        right: -10px;
    }
`

const NotesContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 500px;

    @media (max-height: 740px) {
        height: 330px
    }

    @media (max-width: 480px) {
        height: 330px;
    }
`

NotesContent.formats = [
    // 'formats',
    // 'background',
    'bold',
    'color',
    'font',
    'header',
    'size',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
    'code-block',
];

const Body = styled.div`
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: 100%;
`

const Header = styled.div`
    border-bottom: 1px solid;
    padding: 15px;
    font-weight: 500;
    display: flex;
    align-items: center;

    .btn {
        cursor: pointer;
    }

    span {
        margin-left: 5px;
    }
`

const HeaderLeft = styled.div`
    flex: 1;
    display: flex;
    align-items: center;

    input {
        border: none;
        font-weight: 500;
        font-size: 14px;
        width: -webkit-fill-available;
    }
`

const HeaderRight = styled.div`
    display: flex;
    align-items: center;

    > * {
        margin-left: 10px;
        cursor: pointer;
    }
`

export const Scroll = styled(ScrollView)`
	display: flex;
	flex-direction: column;
    height: 100%;
    word-break: break-word;
    position: relative;
    margin-bottom: 10px;
    color: ${props => props.defColor} !important;

    a {
        color: ${props => props.color} !important;
        text-decoration: none;
        font-weight: 500;
    }

    .notes-toolbar {
        padding-top: 10px;
        border: none;
        position: sticky;
        top: 0;
        background-color: ${props => props.bgColor};
        z-index: 2;
    }
`

export default NotesContent
