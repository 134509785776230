import React, { Component } from 'react'
import styled from 'styled-components'

import LoopApi from 'helpers/LoopApi'
import ExpiredMagicLink from './ExpiredMagicLink'
import Loader from '../Meeting2.0/Loader'
import UserArchived from './UserArchived'

export default class FinishSignup extends Component {
	constructor(props) {
		super(props)
		this.state = {
			loading: true,
			errorMessage: false,
            archived: false
		}
	}

  async componentDidMount() {
		try {
			localStorage.removeItem('token')
			localStorage.setItem('magiclink_token', this.props.match.params.token_code)
			const resp = await LoopApi(null, 'VerifyMagicLink')
			if(resp?.success && resp?.newToken?.token) {
				localStorage.setItem('token', resp.newToken.token)
				const meetingResp = await LoopApi(null, 'GetMeetings')
				if (!meetingResp.meetings || meetingResp.meetings.length === 0) {
					return this.props.history.push(`/create-or-join?token=${resp?.newToken?.token}`)
				} else if (
					localStorage.lastMeeting &&
					meetingResp.meetings.find(m => m.name === localStorage.lastMeeting)
				) {
					return this.props.history.push(`/${localStorage.lastMeeting}?token=${resp?.newToken?.token}`)
				}

				this.props.history.push(`/create-or-join?token=${resp?.newToken?.token}`)
			} else {
				this.setState({ errorMessage: true, archived: resp?.archived })
				localStorage.removeItem('magiclink_token')
			}

		} catch (err) {
			this.setState({errorMessage: true})
			// console.log("error: ", err)
		} finally {
			this.setState({loading: false})
			localStorage.removeItem('magiclink_token')
		}
  }

	render() {
        console.log(this.state)
		return (
			<Container>
				{(this.state.loading && !this.state.errorMessage) ? <Loader /> : this.state.archived ? <UserArchived push={this.props.history.push} /> : <ExpiredMagicLink push={this.props.history.push}/>}
			</Container>
		)
	}
}


const Container = styled.div`
	display: flex;
	flex: 1;
	align-items: center;
	justify-content: center;
	font-size: 16px;
`
