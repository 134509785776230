import React from 'react'
import Reflux from 'reflux'
import styled from 'styled-components'

import { AuthStore } from '/stores/AuthStore'
import Modal from '/components/Modalv2'
import LoopApi from '/helpers/LoopApi'
import { AuthActions } from '../../../stores/AuthStore'
import { IoIosCloseCircle as RemoveIcon } from '@react-icons/all-files/io/IoIosCloseCircle'
import ReactVisibilitySensor from 'react-visibility-sensor'
import { Img } from 'react-image'
import Loader from '../../../components/Loader'
import Grapl from '../../../assets/Icon-SVG.svg'
import useToastify from '../../../helpers/useToastify'
import SuccessErrorToast from '../../../components/Notifications/SuccessErrorToast'
import { ThemingStore } from '../../../stores/ThemingStore'
const IconSvg = <img src={Grapl} alt="Grapl"/>

export default class SetUserAvatar extends Reflux.PureComponent {
	constructor(props) {
		super(props)

		this.state = {
			file: '',
			loading: false,
			jwt: {}
		}

		this.stores = [AuthStore, ThemingStore]
		this.storeKeys = ['jwt', 'color_scheme']

		this.handleFile = this.handleFile.bind(this)
		this.submit = this.submit.bind(this)
	}

	handleFile(e) {
		const reader = new FileReader()
		const file = e.target.files[0]

		// if(file.type === 'image/gif') {
		// 	e.target.value = ''
		// 	return
		// }
		const imageExts = [
			".jpg",
			".jpeg",
			".png",
			".heif",
			".heic"
		]

		const isSupported = imageExts.find((ext) => e.target.value.endsWith(ext))
		if(!!!isSupported) {
			return useToastify({
				message: () => SuccessErrorToast({ message: 'File type not supported.', type: 'error' }),
				position: "top-right",
				autoClose: 1500,
				closeButton: false,
				hideProgressBar: true,
				className: this.state.color_scheme === 'Light' ? 'toastL' : 'toastD',
				bodyClassName: "grow-font-size",
			})
		}
		

		reader.onload = upload => {
			this.setState({
				file: URL.createObjectURL(file),
				image_info: {
					data_uri: upload.target.result,
					filename: file.name,
					filetype: file.type,
				}
			})
		}

		reader.readAsDataURL(file)
		e.target.value = ''
	}

	async submit(event) {
		const { image_info } = this.state
		event.preventDefault()
		this.setState({ loading: true })
		try {
			const token = await LoopApi(null, 'SetAvatar', { image_info })
			this.setState({ loading: false })
			if (!!token && token.token) {
				localStorage.token = token.token
				AuthActions.SetJwt(token.token)
				this.props.closeModal()
				// location.reload(true)
			}
		} catch (err) {
			this.props.closeModal()
			console.error(err)
		}
	}

	componentWillUnmount() {
		this.setState({ loading: false })
	}

	render() {
		const { data = {} } = this.state.jwt
		return (
			<Modal closeModal={this.props.closeModal}>
				<Container>
					<Heading>Set Your Profile Icon:</Heading>
					<SubHeading>Allowed Image Types: .jpg .png, .jpeg, .heif, .heic</SubHeading>
					<div style={{position: 'relative'}}>
						{
							this.state.file && !this.state.loading && <RemoveIcon className="remove-icon" title="Remove avatar" onClick={() => {
								this.setState({ file: '', image_info: '' })
							}}/>
						}
						<ReactVisibilitySensor>
							{
								(this.state.image_info?.filetype === 'image/heif' || this.state.image_info?.filetype === 'image/heic') ?
								<PreviewImage 
								key={`https://ui-avatars.com/api/?name=${''}&format=svg&background=${this.state.color_scheme === 'Light' ? 'F2F4F6' : '21212B'}&color=fefefe` || 'https://grapl-storage.s3.ap-southeast-1.amazonaws.com/Grapl.svg'} 
								src={[`https://ui-avatars.com/api/?name=${''}&format=svg&background=${this.state.color_scheme === 'Light' ? 'F2F4F6' : '21212B'}&color=fefefe`, 'https://grapl-storage.s3.ap-southeast-1.amazonaws.com/Grapl.svg']} 
								loader={<Loader containerWidth="100px" containerHeight="100px" circle/>} 
								alt={data.username || data.name} 
								unloader={IconSvg} 
								/> :
								<PreviewImage 
									key={this.state.file || data.avatar_url || `https://ui-avatars.com/api/?name=${data.username || data.name || ''}&format=svg&background=${data.color || 'random'}&color=fefefe` || 'https://grapl-storage.s3.ap-southeast-1.amazonaws.com/Grapl.svg'} 
									src={[this.state.file, data.avatar_url, `https://ui-avatars.com/api/?name=${data.username || data.name || ''}&format=svg&background=${data.color || 'random'}&color=fefefe`, 'https://grapl-storage.s3.ap-southeast-1.amazonaws.com/Grapl.svg']} 
									loader={<Loader containerWidth="100px" containerHeight="100px" circle/>} 
									alt={data.username || data.name} 
									unloader={IconSvg} 
								/>

							}
						</ReactVisibilitySensor>
						{/* <PreviewImage src={this.state.file || data.avatar_url || `https://ui-avatars.com/api/?name=${data.username}&format=svg&background=${data.color || 'random'}&color=fefefe`} alt="profile_img" /> */}
					</div>
					{
						(this.state.image_info?.filetype === 'image/heif' || this.state.image_info?.filetype === 'image/heic') && 
						<div className='notsupported error'>Initial image preview is not currently supported for HEIF and HEIC files</div>
					}
					{/* <UploadButton >{this.state.loading ? 'Uploading' : 'Upload Image'}</UploadButton> */}

					<Picker type="file" onChange={this.handleFile} disabled={this.state.loading} id="upload-profile-btn" hidden accept="image/jpg, image/jpeg, image/png, image/heif, image/heic"/>
					{
						!this.state.file ? 
						<UploadButton htmlFor="upload-profile-btn" className="button primary" disabled={this.state.loading}>{this.state.loading ? 'Uploading' : 'Upload Image'}</UploadButton>
						:
						<React.Fragment>
							<UploadButton className={`button primary ${!this.state.file || this.state.loading ? 'disabled' :''}`} disabled={!this.state.file || this.state.loading} onClick={this.submit}>{this.state.loading ? 'Saving Profile' : 'Save Profile'}</UploadButton>
						</React.Fragment>
					}
				</Container>
			</Modal>
		)
	}
}

const Picker = styled.input.attrs({ type: 'file' })`
	margin-top: 20px;
	margin-bottom: 20px;
`

const PreviewImage = styled(Img)`
	border-radius: 50%;
	height: 100px;
	width: 100px;
	object-fit: cover;
`

const UploadButton = styled.label`
	user-select: none;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	padding: 10px;
	transition: all 0.05s ease-out;
	font-weight: ${props => props.theme.textRegular};
	font-size: ${props => props.theme.textMD};
	border: none;
	margin: 4px;
	margin-top: 10px;

	&.disabled {
		opacity: 0.5;
		cursor: not-allowed;
	}
`

const Container = styled.div`
	padding: 24px;
	display: flex;
	flex-direction: column;
	align-items: center;

	.remove-icon {
		position: absolute;
		font-size: 24px;
		right: 0;
		top: 0;
		opacity: 0.8;
		cursor: pointer;

		&:hover {
			transform: scale(1.1);
		}
	}

	.notsupported {
		font-size: 11px;
		margin-top: 10px;
		width: 210px;
	}
`

const SubHeading = styled.div`
	margin: 6px 16px 16px 16px;
	color: #888;
	font-size: 12px;
`

const Heading = styled.h4`
	font-weight: 500;
`
