import React, { PureComponent } from 'react'
import styled from 'styled-components'
import { FaEye as EyeIcon } from '@react-icons/all-files/fa/FaEye'
import { FaEyeSlash as EyeSlashIcon } from '@react-icons/all-files/fa/FaEyeSlash'
import { FaTimes as RemoveIcon } from '@react-icons/all-files/fa/FaTimes'
import ReactTooltip from 'react-tooltip'

import { WidgetActions } from '/stores/MainStore'
import { ModalActions } from '/stores/ModalStore'
import Sagas from '/helpers/Sagas'
import CustomTip from '../../../helpers/CustomTip'

export default class KeynoteHelperButtons extends PureComponent {
	constructor(props) {
		super(props)
		this.minimizeWidget = this.minimizeWidget.bind(this)
		this.removeWidget = this.removeWidget.bind(this)
	}

	removeWidget() {
		WidgetActions.SelectWidget(this.props.widget_name)
		ModalActions.SetModal('RemoveWidget')
	}

	minimizeWidget(e) {
		e.stopPropagation()

		// setTimeout(() => {

		// 	const tools = process.env.REACT_APP_TOOLS || 'images,pdfpresenter,todo,transcription,notes,files,links'
		// 	const toolsArray = tools.split(",").map((item) => { return item.trim() }).filter((item) => !!item)
		// 	toolsArray.map((tool) => WidgetActions.HideSync(tool))
		// }, 100);


		if (this.props.onCallLocked) {
			const minimizedWidgets = this.props.minimized || []
			const ind = minimizedWidgets.indexOf(this.props.widget_name)
			if (ind === -1) {
				minimizedWidgets.push(this.props.widget_name)
			} else {
				minimizedWidgets.splice(ind, 1)
			}
			Sagas.changeSetting('minimizedWidgets', minimizedWidgets)
			WidgetActions.HideSync(this.props.widget_name, true)
		} else {
			WidgetActions.HideSync(this.props.widget_name)
		}
	}

	render() {
		const is_minimized = this.props.minimized.includes(this.props.widget_name)

		return (
			<Container className="helperButtons">
				{is_minimized && this.props.onCallLocked ? (
					<EyeSlashIcon
						className="helperButtonShow helperbutton-open"
						onClick={this.minimizeWidget}
						size={18}
						title="Show sync"
						// data-tip="Show sync"
						onMouseEnter={() => {ReactTooltip.hide(this.props.parentRef.current); }}
						onMouseLeave={() => {ReactTooltip.show(this.props.parentRef.current); ReactTooltip.rebuild();}}
						// onMouseOver={() => {  ReactTooltip.rebuild(); }}
					/>
				) : (
					<EyeIcon
						className="helperbutton-open"
						onClick={this.minimizeWidget}
						size={18}
						data-tip
						data-for="hide-sync"
						onMouseEnter={() => {ReactTooltip.hide(this.props.parentRef.current); }}
						onMouseLeave={() => {ReactTooltip.show(this.props.parentRef.current); ReactTooltip.rebuild(); }}
						// onMouseOver={() => { ReactTooltip.hide(this.props.parentRef.current); ReactTooltip.rebuild(); }}
					/>
				)}
				<CustomTip top={20} left={10} class='bottom-left' place='right' tip='Hide Sync' dataFor='hide-sync' />

				{/* <RemoveIcon className={`${is_minimized ? 'helperButtonShow' : ''} helperbutton-close`} onClick={this.removeWidget} size={20} title="Remove sync" data-tip="Remove sync" /> */}
				{/* <ReactTooltip /> */}
			</Container>
		)
	}
}

const Container = styled.div`
	position: absolute;
	right: 1px;
	top: 1px;
	display: flex;
	align-items: center;

	${(props) => props.is_minimized && 'top: 5px;'}

	transition: top 0.3s ease-out;


	> * {
		opacity: 0;
		/* &.helperButtonShow {
			opacity: 1;
		} */
		transition: opacity 0.3s ease-out;
		cursor: pointer;
		margin: 4px;
		&:hover {
			opacity: 0.8;
		}
	}
`
