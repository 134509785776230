import React from 'react';
import Reflux from 'reflux'
import styled from 'styled-components';

import Modal from '/components/Modalv2';
import { FiInfo as InfoIcon } from '@react-icons/all-files/fi/FiInfo';
import LoopApi from '../../../helpers/LoopApi'
import useToastify from '../../../helpers/useToastify';
import SuccessErrorToast from '../../../components/Notifications/SuccessErrorToast';
import { ThemingStore } from '../../../stores/ThemingStore';

const { GlobalState } = require('reflux');


export default class RemoveForever extends Reflux.Component {
    constructor(props) {
        super(props);

        this.Remove = this.Remove.bind(this);
        this.store = ThemingStore
        this.storeKeys = ['color_scheme']
    }

    async Remove() {
        const { id, name, getArchivedMeetings } = GlobalState.modal
        try {
            const response = await LoopApi(null, 'RemoveMeetingForever', { id, name })
            if(response?.success) {
                useToastify({
                    message: () => SuccessErrorToast({ message: 'Successfully removed room forever', type: 'success' }),
                    position: "top-right",
                    autoClose: 1500,
                    closeButton: false,
                    hideProgressBar: true,
                    className: this.state.color_scheme === 'Light' ? 'toastL' : 'toastD',
                    bodyClassName: "grow-font-size",
                })
            }
            await getArchivedMeetings()
        } catch (err) {
            //console.log("error removing room forever ", err)
        }
        this.props.closeModal();
    }

    render() {
        return (
            <Modal closeModal={this.props.closeModal} dismissible={false}>
                <Container>
                    <Icon className='container error-imp'>
                        <InfoIcon />
                    </Icon>
                    <Title>Careful!</Title>
                    <Info>Are you sure you want to remove this room forever?</Info>
                    <Options>
                        <Button onClick={this.Remove} id='dismiss' className='button danger'>
                            Ok
                        </Button>
                        <Button onClick={this.props.closeModal} id='dismiss' className='button default'>
                            Cancel
                        </Button>
                    </Options>
                </Container>
            </Modal>
        );
    }
}

const Icon = styled.div`
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    color: inherit;
`;

const Info = styled.div`
    font-weight: 400;
    color: inherit;
    line-height: 20px;
    margin: 10px 0 15px;
    opacity: 0.5;
`;

const Container = styled.div`
    position: relative;
    text-align: center;
    padding: 30px;
    display: flex;
    align-items: center;
    flex-direction: column;
    max-width: 420px;
`;

const Title = styled.div`
    font-size: 18px;
    font-weight: 500;
    margin: 15px 0;
    &::first-letter {
        text-transform: uppercase;
    }
`;

const Options = styled.div`
    display: flex;
    width: 100%;
`;

const Button = styled.div`
    cursor: pointer;
    display: flex;
    flex: 1;
    justify-content: center;
    padding: 14px;
    margin: 4px;
    font-weight: 400;
`;
