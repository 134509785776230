import React from 'react'
import styled from 'styled-components'
import Reflux from 'reflux'

import Sagas from '/helpers/Sagas'
import { ThemingStore } from '../../../stores/ThemingStore'

export default class ChangeColors extends Reflux.Component {
	constructor(props) {
		super(props)

		this.store = ThemingStore
		this.storeKeys = ['color']
	}

	ChangeColor(e) {
		Sagas.changeSetting('color', e.target.value)
	}

	render() {
		return (
			<Container>
				<Underlay onClick={this.props.closeModal} />
				<Internal>
					<input
						type="color"
						value={this.state.color.string()}
						onChange={this.ChangeColor}
					/>
				</Internal>
			</Container>
		)
	}
}

const Container = styled.div`
	position: fixed;
	left: 0;
	right: 0;
	bottom: 0;
	top: 0;
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	padding-top: 52px;
	padding-left: 8px;
`

const Underlay = styled.div`
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	top: 0;
`

const Internal = styled.div`
	z-index: 1;
	background-color: white;
	border: 1px solid #888;
	border-radius: 6px;
	box-shadow: ${props => props.theme.shadows.heavy};
`
