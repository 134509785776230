import React, { Component } from 'react'
import styled from 'styled-components'
import { ModalActions } from '../../../stores/ModalStore'

import Modal from '/components/Modalv2'
import LoopApi from '/helpers/LoopApi'

export default class ChangeMeetingPassword extends Component {
  constructor(props) {
    super(props)

    this.setPassword = this.setPassword.bind(this)
    this.state = { newPassword: '' }
  }

  setPassword(event) {
    this.setState({ newPassword: event.target.value || null })
  }

  handleSubmit(event) {
    event.preventDefault()

    try {
      LoopApi(null, 'ChangeMeetingPassword', {
        password: this.state.newPassword,
      })
      this.props.closeModal()
    } catch (err) {
      this.props.closeModal()
      console.error(err)
    }
  }

  render() {
    return (
      <Modal closeModal={this.props.closeModal}>
        <SendForm onSubmit={this.handleSubmit.bind(this)}>
          <Contents>
            <Title className='header'>Room Password</Title>
            <Details className='subheader'> Leave it blank to remove password</Details>
          </Contents>
          {/* <div>New Password (blank for no password):</div> */}
          <FormField
            data-lpignore="true"
            type="password"
            autocomplete="off"
            id="search-in-name-to-throw-off-pass-managers"
            className="topbar border-light"
            placeholder="Password"
            value={this.state.email}
            onChange={this.setPassword}
          />
          <SubmitButton value="Set Password" type="submit" className="button primary" />
          <SwitchLink
            onClick={() => {
              ModalActions.SetModal('ChangeSettings')
            }}
          >
            Cancel
          </SwitchLink>
        </SendForm>
      </Modal>
    )
  }
}

const SwitchLink = styled.div`
	text-decoration: underline;
	text-align: center;
	cursor: pointer;
	margin-top: 30px;
	font-weight: 500;

	&.disabled {
		cursor: default;
		opacity: 0.5;
	}
`

const Contents = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  text-align: center;
`;

const Title = styled.div`
  font-weight: ${(props) => props.theme.textBold};
  font-size: ${(props) => props.theme.textLG};
`;

const Details = styled.div`
  font-weight: ${(props) => props.theme.textRegular};
  font-size: ${(props) => props.theme.textMD};
  padding-top: 10px;
`;

const SendForm = styled.form`
  padding: 30px;
  display: flex;
  flex-direction: column;
  min-width: 350px;
`

const FormField = styled.input`
  font-size: 16px;
  border: 1px solid;
  margin-top: 12px;
  border-radius: 999px;
`

const SubmitButton = styled.input`
  margin-top: 12px;
  background-color: ${props => props.theme.color.rotate(90).string()};
  border: none;
  font-size: 14px;
  /* box-shadow: ${props => props.theme.shadows.light}; */
  color: white;
  padding: 9px;
  cursor: pointer;

  &:disabled {
    opacity: 0.5;
    cursor: initial;
    pointer-events: none;
  }

  &:hover {
    opacity: 0.9;
  }
`
