import React from 'react'
import Reflux from 'reflux'
import styled from 'styled-components'

import LoopApi from '/helpers/LoopApi'
import NukeIcon from '../../../assets/icons/nuke.svg'
import { AuthStore } from '/stores/AuthStore'
import { ModalActions } from '/stores/ModalStore'

export default class Locked extends Reflux.PureComponent {
	constructor(props) {
		super(props)
		this.store = AuthStore
		this.storeKeys = ['jwt']
		this.makeMeeting = this.makeMeeting.bind(this)
		this.makeAccount = this.makeAccount.bind(this)
	}

	async makeMeeting() {
		const resp = await LoopApi(null, 'CreateMeeting', {
			name: this.props.match.params.meetingName,
			is_public: true,
		})
		//console.log('made new meeting', resp)
		this.props.JoinedMeeting()
	}

	makeAccount() {
		if (
			this.state.jwt &&
			this.state.jwt.data &&
			this.state.jwt.data.type === 'guest'
		) {
			ModalActions.SetModal('SendVerificationEmail')
		} else {
			this.props.push('/contact')
		}
	}

	render() {
		//console.log()
		const ren =
			this.state.jwt &&
				this.state.jwt.data &&
				this.state.jwt.data.type === 'account' ? (
				<Text>
					<Button onClick={this.makeMeeting}>Remake As New Room</Button>
				</Text>
			) : (
				<Text>
					<Button onClick={this.makeAccount}>Create an Account</Button> so this
					doesn't happen again.
				</Text>
			)
		return (
			<Container>
				<Pic src={NukeIcon} />
				<Text>You didn't make it in time, the room expired.</Text>
				{ren}
			</Container>
		)
	}
}

const Button = styled.span`
	margin: 2px;
	color: white;
	font-weight: 500;
	padding: 6px;
	border-radius: 2px;
	background-color: ${props => props.theme.colorSecondary.string()};
	cursor: pointer;
	transition: box-shadow 0.3s ease-out;
	box-shadow: ${props => props.theme.shadows.light};
	&:hover {
		box-shadow: ${props => props.theme.shadows.heavy};
	}
`

const Container = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
`

const Text = styled.div`
	text-align: center;
	color: white;
	font-weight: 500;
	line-height: 2.5;
	text-align: center;
	font-size: 18px;
`

const Pic = styled.img`
	margin-bottom: 24px;
	width: 300px;
	max-width: 80%;
`
