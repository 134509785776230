import React from 'react'
import Reflux from 'reflux'

import Modals from '../Meeting/Modals'
import { ModalActions, ModalStore } from '/stores/ModalStore'
import { WidgetActions, MainStore } from '/stores/MainStore'
import AllWidgets from '../../loop-widgets'

export default class ModalManager extends Reflux.Component {
	constructor(props) {
		super(props)

		this.stores = [ModalStore, MainStore]
		this.storeKeys = [
			'currentModal',
			'meetingName',
			'widgets',
			'users',
			'selectedWidget',
		]

		this.state = {
			currentModal: null,
		}

		this.closeModal = this.closeModal.bind(this)
	}

	closeModal() {
		WidgetActions.SelectWidget('')
		ModalActions.SetModal(null)
	}

	render() {
		return (
			this.state.currentModal && (
				<Modals
					AllWidgets={AllWidgets}
					meeting={{ widgets: this.state.widgets, users: this.state.users }}
					meetingName={this.state.meetingName}
					closeModal={this.closeModal}
					openOtherModal={modalName => ModalActions.SetModal(modalName)}
					currentModal={this.state.currentModal}
					history={this.props.history}
					selectedWidget={this.state.selectedWidget}
				/>
			)
		)
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			this.state.meetingConnection === 'Connecting' ||
			this.state.meetingConnection === 'Reconnecting'
		) {
			ModalActions.SetModal(this.state.meetingConnection)
		}
	}
}
