import React, {memo, useState, useEffect} from 'react'
import styled from 'styled-components'

import Modal from '/components/Modalv2'
// import check from '../../../../src/assets/icons/check.svg';
// import checkLight from '../../../../src/assets/icons/check-light.svg';
import LoopApi from '../../../helpers/LoopApi'
// import moment from 'moment';
// import { ModalActions } from '/stores/ModalStore';
// import { MdUnarchive } from 'react-icons/md';
import {FiSearch as SearchIcon} from '@react-icons/all-files/fi/FiSearch'
import {BiLoaderCircle} from 'react-icons/bi'
import {useHistory} from 'react-router'
import moment from 'moment'
const {GlobalState} = require('reflux')

const TranscriptionSearch = (props) => {
  // const [loading, setLoading] = useState(false)
  const history = useHistory()
  const [result, setResult] = useState('')
  const [month, setMonth] = useState(
    moment(`${moment().format('Y')}-1`, 'YYYY-M').format('M'),
  )
  const [year, setYear] = useState(moment().format('Y'))
  const [day, setDay] = useState('1')
  const [days, setDays] = useState([])
  const [isAll, setIsAll] = useState(true)
  const [value, setValue] = useState("")
  
  const [to, setTo] = useState(null)

  React.useEffect(() => {
    let days = []
    while (days.length < moment(`${year}-${month}`, 'YYYY-M').daysInMonth()) {
      days.push(
        moment(`${year}-${month}`, 'YYYY-M').daysInMonth() - days.length,
      )
    }
    setDays(days.reverse())
  }, [month])

  React.useEffect(() => {
    clearTimeout(to)
    const roomId = history.location.pathname.split('/')[2]

    setTo(setTimeout(async () => {
        await searchRequest(roomId)
        clearTimeout(to)
    }, 2000));


  }, [value, isAll])


  const searchRequest = async (roomId) => {
    const res = await LoopApi(null, 'SearchTranscription', {
        transcription: value,
        roomId,
        date: moment(`${month}-${day}-${year}`, 'M-D-YYYY').format(),
        isAll
      })
      setResult(res)
  }

  const searchHandler = async (event) => {
    setResult(null)
    setValue(event.target.value)
  }
 

  const selectTranscription = (data) => {
    const {
      setSelectedDay,
      setSelectedMonth,
      setSelectedYear,
      setSelectedData,
      setActiveDate,
    } = GlobalState.modal

    const month = moment(data.updatedAt).format('MMMM')
    const day = moment(data.updatedAt).format('D')
    const year = moment(data.updatedAt).format('YYYY')

    setSelectedMonth(month)
    setSelectedDay(day)
    setSelectedYear(year)
    setActiveDate(moment(data.updatedAt).format('MMMM D, YYYY'))
    setSelectedData({transcriptions: [data]})
  }

  const generateYears = () => {
    let years = []
    while (years.length <= 10) {
      years.push(moment().format('Y') - years.length)
    }

    return years
  }

  const generateMonths = () => {
    return moment.months()
  }

  const yearChanged = (event) => {
    setYear(event.target.value)
  }

  const monthChanged = (event) => {
    setMonth(event.target.value)
  }

  const dayChanged = (event) => {
    setDay(event.target.value)
  }

  const handleCheckbox = event => {
    setIsAll(event.target.checked)
  }


  return (
    <Modal closeModal={props.closeModal} dismissible={true}>
      <Container>
        <Contents>
          <InputWrapper>
            <SearchIcon className="search-ico" size={40} />
            <Search
              placeholder="Enter keywords"
              onChange={searchHandler}
              autoFocus
            />
          </InputWrapper>
        <div className='t-checkbox'>
            <input type="checkbox" onChange={handleCheckbox} checked={isAll} /> Across all transcriptions
            </div>
          <DatePicker>
            <select onChange={yearChanged} disabled={isAll}>
              {generateYears().map((y) => {
                return <option value={y}>{y}</option>
              })}
            </select>

            <select onChange={monthChanged} disabled={isAll}>
              {generateMonths().map((m, i) => {
                return <option value={i + 1}>{m}</option>
              })}
            </select>

            <select onChange={dayChanged} disabled={isAll}>
              {days.map((d, i) => {
                return <option value={i + 1}>{d}</option>
              })}
            </select>
          </DatePicker>
          {result === null ? (
            <Loader className="icon-spin" size={20} />
          ) : Array.isArray(result) && result.length === 0 ? (
            `${result.length || 0} result/s`
          ) : result !== '' ? (
            <div>
              <div>
                <div className="result-count">{result.length} result/s</div>
                <div className="result-wrapper">
                  {Array.isArray(result) &&
                    result.map((r) => {
                      return (
                        <div
                          className="transcription-selection"
                          onClick={() => selectTranscription(r)}
                        >
                          {moment(r.created_at).format('LLLL')}
                        </div>
                      )
                    })}
                </div>
              </div>
            </div>
          ) : null}
        </Contents>
        {/* transcription result here */}
      </Container>
    </Modal>
  )
}

export default TranscriptionSearch

const Contents = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;

  text-align: start;
  select {
    padding: 5px;
    border: 1px solid grey;
    border-radius: 5px;
    margin-right: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .t-checkbox {
  }

  .result-count {
    margin-bottom: 8px;
  }

  .result-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .transcription-selection {
      cursor: pointer;
      margin-bottom: 5px;
    }

    .transcription-selection:hover {
      background: darkgrey;
    }
  }
`
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 500px;

  .row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    margin-bottom: 3px;
    align-items: center;
  }

  .icon-spin {
    -webkit-animation: icon-spin 2s infinite linear;
    animation: icon-spin 2s infinite linear;
  }

  @-webkit-keyframes icon-spin {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg);
    }
  }

  @keyframes icon-spin {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg);
    }
  }
`

const Loader = styled(BiLoaderCircle)`
  align-self: center;
`

const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const Search = styled.input`
  border: 0;
  /* border-bottom: 1px solid; */
  font-size: 2em !important;
`

const Title = styled.div`
  font-weight: ${(props) => props.theme.textBold};
  font-size: ${(props) => props.theme.textLG};
`

const Details = styled.div`
  font-weight: ${(props) => props.theme.textRegular};
  font-size: ${(props) => props.theme.textMD};
  padding-top: 10px;
`

const DatePicker = styled.div``
