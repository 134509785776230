import React from 'react'
import Reflux from 'reflux'
import styled from 'styled-components'

import LoopApi from '/helpers/LoopApi'
import Modal from '/components/Modal'

import { FaArrowLeft as BackIcon } from '@react-icons/all-files/fa/FaArrowLeft'
import ZoomLogo from '../../../assets/icons/ZoomLogo.png'
import AppearinLogo from '../../../assets/icons/AppearinLogo.svg'

const flows = [
	{ name: 'Zoom', icon: ZoomLogo },
	{ name: 'Appear In', icon: AppearinLogo },
	{ name: 'None (Testing)' },
]

class FlowList extends React.PureComponent {
	render() {
		const mapped_flows = flows.map((f, ii) => (
			<Item
				key={f.name + 'flow' + ii}
				onClick={() => this.props.onSelect(f.name)}
			>
				<div>{f.name}</div>
				{f.icon && <Logo src={f.icon} />}
			</Item>
		))
		return <FlowContainer>{mapped_flows}</FlowContainer>
	}
}

const Item = styled.div`
	padding: 4px;
	cursor: pointer;
	&:hover {
		background-color: rgba(0, 0, 0, 0.12);
	}
	display: flex;
	justify-content: space-between;
	align-items: center;
`
const Logo = styled.div`
	height: 32px;
	width: 48px;
	background-image: url("${props => props.src}");
	background-position: right center;
	background-size: contain;
	background-repeat: no-repeat;
`
const FlowContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: stretch;
	margin-top: 12px;
	font-weight: 500;
`

export default class ExternalConference extends Reflux.Component {
	constructor(props) {
		super(props)
		this.state = {
			input: '',
			submitting: false,
			error_text: '',
			wanted_flow: null,
		}
		this.input = null
		this.setInput = this.setInput.bind(this)
		this.submit = this.submit.bind(this)
	}
	async submit(e) {
		try {
			e.preventDefault()
			if (
				this.state.submitting ||
				(!this.state.input && this.state.wanted_flow !== 'None (Testing)')
			) {
				return
			}
			this.setState({ submitting: true, error_text: '' })
			const resp = await LoopApi(null, 'JoinExternal', {
				connection_link: this.state.input,
				dry_run: !this.state.input,
			})
			if (resp.error) {
				return this.setState({ submitting: false, error_text: resp.msg })
			}
			this.setState({ submitting: false, input: '' })
			this.props.closeModal()
		} catch (e) {
			this.setState({ submitting: false, error_text: e.message })
		}
	}
	setInput(e) {
		this.setState({ input: e.target.value })
	}
	componentDidUpdate(prevProps, prevState) {
		if (this.state.wanted_flow !== prevState.wanted_flow && this.input) {
			this.input.focus()
		}
	}
	render() {
		return (
			<StyledModal closeModal={this.props.closeModal}>
				<Container>
					<Head>
						<Title>Start Presentation</Title>
						<SubTitle>
							{this.state.wanted_flow
								? 'Paste the link to join below'
								: 'Choose a presentation platform'}
						</SubTitle>
					</Head>
					{this.state.wanted_flow ? (
						<Form onSubmit={this.submit}>
							<Back onClick={() => this.setState({ wanted_flow: null })}>
								<BackIcon size={18} color="white" />
							</Back>
							<Input
								ref={r => (this.input = r)}
								type="text"
								onChange={this.setInput}
								value={this.state.input}
								placeholder="Link To Join"
							/>
							<Submit
								type="submit"
								disabled={
									this.state.submitting ||
									(!this.state.input &&
										this.state.wanted_flow !== 'None (Testing)')
								}
								onClick={this.submit}
								value="Submit"
							/>
							{this.state.error_text && (
								<ErrorText>{this.state.error_text}</ErrorText>
							)}
						</Form>
					) : (
						<FlowList
							onSelect={wanted_flow => this.setState({ wanted_flow })}
						/>
					)}
				</Container>
			</StyledModal>
		)
	}
}

const Container = styled.div`
	padding: 24px 32px;
	display: flex;
	align-items: stretch;
	justify-content: center;
	flex-direction: column;
`

const ErrorText = styled.div`
	color: red;
	margin-top: 6px;
	font-weight: 400;
	font-size: 12px;
`

const Form = styled.form`
	margin: 6px;
`

const Input = styled.input`
	border-radius: 2px;
	padding: 4px;
	box-shadow: none;
	border: 1px solid #555;
	font-size: 16px;
`

const Submit = styled.input`
	border: none;
	margin-top: 12px;
	background-color: ${props => props.theme.color.string()};
	border-radius: 2px;
	color: white;
	padding: 4px;
	font-size: 16px;
	font-weight: 500;
	margin-left: 5px;
	box-shadow: ${props => props.theme.shadows.light};
	transition: box-shadow 0.3s ease-out, opacity 0.3s ease-out;
	&:hover {
		opacity: 0.95;
		box-shadow: ${props => props.theme.shadows.heavy};
	}
	&:disabled {
		opacity: 0.5;
		pointer-events: none;
	}
`

const StyledModal = styled(Modal)`
	padding: 24px;
	text-align: left;
	overflow-y: hidden !important;
`

const Title = styled.h1`
	text-align: center;
	font-size: ${props => props.theme.textXL};
	font-weight: ${props => props.theme.textRegular};
`

const SubTitle = styled.h1`
	padding-top: 6px;
	margin-top: 6px;
	text-align: center;
	font-size: ${props => props.theme.textMD};
	font-weight: ${props => props.theme.textRegular};
`

const Head = styled.div`
	margin-top: 12px;
	text-align: center;
	font-size: 16px;
	font-weight: 500;
`

const Back = styled.div`
	position: absolute;
	top: 6px;
	left: 6px;
	width: 32px;
	height: 32px;
	border-radius: 50%;
	background: ${props => props.theme.colors.secondary.string()};
	color: white;
	align-items: center;
	justify-content: center;
	display: flex;
	cursor: pointer;
	&:hover {
		opacity: 0.85;
	}
`
