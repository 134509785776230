import React, { Component } from 'react';
import styled from 'styled-components';

import Modal from '/components/Modalv2';
import { FiWifi as InfoIcon } from '@react-icons/all-files/fi/FiWifi';

export default class NoInternet extends Component {
    constructor(props) {
        super(props);

        this.Remove = this.Remove.bind(this);
    }

    Remove() {
        this.props.closeModal();
        window.location.reload();
    }

    render() {
        return (
            <Modal closeModal={this.props.closeModal} dismissible={false}>
                <Container>
                    <ContentWrapper>
                        <Icon className='container'>
                            <InfoIcon />
                        </Icon>
                        <div className='content-info'>
                            <Title>Unstable Internet Connection</Title>
                            <Info>Please check your network stability and then reload this page</Info>
                            <Options>
                                <Button onClick={this.Remove} id='dismiss' className='button primary'>
                                    Reload page now
                                </Button>
                            </Options>

                        </div>
                    </ContentWrapper>
                </Container>
            </Modal>
        );
    }
}

const ContentWrapper = styled.div`
    display: flex;

    .content-info {
        text-align: left;
        margin-left: 10px;
    }
`

const Icon = styled.div`
    border-radius: 50%;
    width: 50px;
    height: 50px;
    min-width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    color: inherit;
`;

const Info = styled.div`
    font-weight: 400;
    color: inherit;
    line-height: 20px;
    margin: 10px 0 15px;
    opacity: 0.5;
`;

const Container = styled.div`
    position: relative;
    text-align: center;
    padding: 30px;
    display: flex;
    align-items: center;
    flex-direction: column;
    max-width: 420px;
`;

const Title = styled.div`
    font-size: 18px;
    font-weight: 500;
    margin: 0 0 15px;
    &::first-letter {
        text-transform: uppercase;
    }
`;

const Options = styled.div`
    display: flex;
    width: 100%;
`;

const Button = styled.div`
    cursor: pointer;
    display: flex;
    flex: 1;
    justify-content: center;
    padding: 14px;
    margin: 4px;
    font-weight: 400;
`;
