import React from 'react'
import Reflux from 'reflux'
import styled from 'styled-components'

import Modal from '/components/Modalv2'
import { ModalStore } from '../../../stores/ModalStore'
import { MainStore, WidgetActions } from '../../../stores/MainStore'

export default class TransferRoom extends Reflux.Component {
	constructor(props) {
		super(props)

        this.stores = [ModalStore, MainStore]
        this.storeKeys = ['cb', 'room', 'roomName', 'presentation', 'widgets']

		this.Cancel = this.Cancel.bind(this)
		this.Leave = this.Leave.bind(this)
	}

	Cancel() {
		this.props.closeModal()
	}

	Leave() {
		const mainLeaveFunc = () => {
			sessionStorage.removeItem('roomsAllowed')
			window.location.href = `${process.env.REACT_APP_FRONTEND_URL || 'http://localhost:1234'}/${this.state.room}`
			this.props.closeModal();
			
			window.onunload = () => {
				this.props.history.replace(`/${this.state.room}`)
				window.location.reload();
			}
		}

		let tokens = {}
		const session = sessionStorage.getItem('external_tokens')

		if(session)
		tokens = JSON.parse(session)

		if(
			this.state.presentation?.googledrive === 'presenting' &&
			!!this.state.widgets?.googledrive?.file &&
			session &&
			!!tokens?.googledrive
		) {
			WidgetActions.GoogleCheckPresenting(tokens.googledrive, mainLeaveFunc)
		} else {
			mainLeaveFunc()
		}
	}

	render() {
		return (
			<Modal closeModal={this.props.closeModal}>
				<Container >
					<Contents>
						<Title className="header">Move to another room</Title>
						<Details className="subheader">You will be redirected to <span className="color-primary">{this.state.roomName || this.state.room}</span></Details>
						{/* <Details className="subheader">Are you sure you want to open <span className="color-primary">{this.state.room}</span>? </Details> */}
					</Contents>

					<Options>
						<Button className="button default" onClick={this.Cancel}>No</Button>
						<Button className="button primary" onClick={this.Leave}>Yes</Button>
					</Options>
				</Container>
			</Modal>
		)
	}
}

const Container = styled.div`
	padding: 12px;
	display: flex;
	flex-direction: column;
	align-items: center;
`

const Contents = styled.div`
	display: flex;
	flex-direction: column;
	padding: 20px;
`

const Title = styled.div`
	font-weight: ${props => props.theme.textBold};
	font-size: ${props => props.theme.textLG};
`

const Details = styled.div`
	font-weight: ${props => props.theme.textRegular};
	font-size: ${props => props.theme.textMD};
	padding-top: 10px;

	.color-primary {
		font-weight: 500;
	}
`

const Options = styled.div`
	display: flex;
	width: 70%;
`

const Button = styled.div`
	cursor: pointer;
	display: flex;
	justify-content: center;
	flex: 1;
	padding: 14px;
	margin: 4px;
`
