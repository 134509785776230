import React, { Component } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import LoopApi from '/helpers/LoopApi';
import Modal from '/components/Modalv2';
import { AiOutlineCheckCircle } from 'react-icons/ai'

export default class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.Cancel = this.Cancel.bind(this);
    this.Request = this.Request.bind(this);

    this._isInputGood = this._isInputGood.bind(this);
    this._validatorUI = this._validatorUI.bind(this);
    this._onChange = this._onChange.bind(this);

    this.state = {
      password: '',
      confirm_password: '',
      inputGood: false,
      success: false,
      type: 'empty',
    };
  }

  Cancel() {
    this.props.closeModal();
  }

  async Request() {
    if (!this._isInputGood()) return;

    try {
      const resp = await LoopApi(null, 'ChangePassword', this.state);
      if (resp) this.setState({ success: true });
    } catch (error) {
      //console.log(error);
    }
  }

  _onChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  _isInputGood() {
    var format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    const notempty = this.state.password && this.state.confirm_password;
    const minLen = this.state.password.length >= 8;
    const maxLen = this.state.password.length <= 16;
    const lowercase = /[a-z]/.test(this.state.password);
    const uppercase = /[A-Z]/.test(this.state.password);
    const numeric = /\d/.test(this.state.password);
    const special = format.test(this.state.password);
    return (
      this.state.password === this.state.confirm_password &&
      notempty &&
      minLen &&
      maxLen &&
      lowercase &&
      uppercase &&
      numeric &&
      special
    );
  }

  _validatorUI() {
    const { password, confirm_password } = this.state;
    var format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    return {
      minMax: password.length >= 8 && password.length <= 16 ? true : false,
      lower: /[a-z]/.test(password) ? true : false,
      upper: /[A-Z]/.test(password) ? true : false,
      numeric: /\d/.test(password) ? true : false,
      symbol: format.test(password) ? true : false,
      confirm: password === confirm_password ? true : false,
    };
  }

  _helper() {
    return <React.Fragment>
      <HelperWrapper className={`${this._validatorUI().minMax ? 'success' : 'error'}`}>
          <CircleIcon /> At least 8-16 characters long
      </HelperWrapper>
      <HelperWrapper className={`${this._validatorUI().lower ? 'success' : 'error'}`}>
          <CircleIcon /> One lowercase character
      </HelperWrapper>
      <HelperWrapper className={`${this._validatorUI().upper ? 'success' : 'error'}`}>
          <CircleIcon /> One uppercase character
      </HelperWrapper>
      <HelperWrapper className={`${this._validatorUI().numeric ? 'success' : 'error'}`}>
          <CircleIcon /> One numeric character
      </HelperWrapper>
      <HelperWrapper className={`${this._validatorUI().symbol ? 'success' : 'error'}`}>
          <CircleIcon /> One special character
      </HelperWrapper>
      <HelperWrapper className={`${this._validatorUI().confirm ? 'success' : 'error'}`}>
          <CircleIcon /> Password matched
      </HelperWrapper>
    </React.Fragment>
    // const { password, confirm_password } = this.state;
    // var format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    // if (password.length < 8) return 'Password must at least 8 characters.';
    // if (password.length > 16) return 'Password must at maximum of 16 characters.';
    // if (!/[a-z]/.test(password)) return 'At least one lowercase character.';
    // if (!/[A-Z]/.test(password)) return 'At least one uppercase character.';
    // if (!/\d/.test(password)) return 'At least one numeric character.';
    // if (!format.test(password)) return 'At least one special character.';
    // if (password !== confirm_password) return 'Please make your password match.';
  }

  render() {
    return (
      <CustomModal closeModal={this.props.closeModal}>
        <Container>
          {this.state.success ? (
            <React.Fragment>
              <Contents>
                <Title className='header'>Password Changed</Title>
                <Details className='subheader'>You can now sign in with your new password.</Details>
              </Contents>

              <Options>
                <Button className='button primary' onClick={this.Cancel}>
                  Confirm
                </Button>
              </Options>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Contents>
                <Title className='header'>Change Password</Title>
                <Details className='subheader'> What would you like your new password to be?</Details>
              </Contents>
              <Form onSubmit={this.Request} inputGood={this._isInputGood()}>
                <Field
                  className="border-light topbar"
                  name='password'
                  type='password'
                  placeholder='New Password'
                  value={this.state.password}
                  onChange={this._onChange}
                />
                <Field
                  className="border-light topbar"
                  name='confirm_password'
                  type='password'
                  placeholder='Confirm Password'
                  value={this.state.confirm_password}
                  onChange={this._onChange}
                />

                {this.state.errored && <p style={{ color: 'red' }}>{this.state.errormsg}</p>}
              </Form>
              <HelperContainer>{this._helper()}</HelperContainer>
              <Options>
                <Button className='button primary' onClick={this.Request} inputGood={this._isInputGood()}>
                  Update
                </Button>
              </Options>
              <div className='cancel-btn' onClick={this.Cancel}>
                  Cancel
              </div>
            </React.Fragment>
          )}
        </Container>
      </CustomModal>
    );
  }
}

const CustomModal = styled(Modal)`
  justify-content: start;
`

const Container = styled.div`
  padding: 30px;
  display: flex;
  flex-direction: column;
  text-align: start !important;
  /* align-items: center; */
  max-width: 450px;
  
  .cancel-btn {
    text-decoration: underline;
    text-align: center;
    cursor: pointer;
    margin-top: 15px;
    font-weight: 500;
  }
`;

const Contents = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  text-align: center;
`;

const Title = styled.div`
  font-weight: ${(props) => props.theme.textBold};
  font-size: ${(props) => props.theme.textLG};
`;

const Details = styled.div`
  font-weight: ${(props) => props.theme.textRegular};
  font-size: ${(props) => props.theme.textMD};
  padding-top: 10px;
`;

const HelperContainer = styled.div`
  font-size: 0.8rem;
  padding-left: 16px;
  width: 100%;
`;

const HelperWrapper = styled.div` 
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  margin-bottom: 2px;
`;

const CircleIcon = styled(AiOutlineCheckCircle)`
  font-size: 1.3rem;
  margin-right: 5px;
`;

const Options = styled.div`
  display: flex;
  width: 100%;
  margin-top: 25px;

`;

const Button = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  flex: 1;
  padding: 14px;
  margin: 4px;
`;

const Field = styled.input`
  flex: 1;
  padding-left: 16px;
  background-color: transparent;
  width: 90%;
  margin: 10px;
  border: 1px solid;
  outline: none;
  border-radius: 999px;

  &::placeholder {
    opacity: 0.5;
  }
`;
const Form = styled.form`
  margin: 10px;
  /* border: ${(props) => (!props.inputGood ? '0.5px solid rgba(255, 0, 0, 0.2)' : '0.5px solid rgba(0, 255, 0, 0.2)')}; */
`;
