import React, { PureComponent } from "react";
import styled from "styled-components";
import ExpiredIcon from './expired.svg'
import {
  colorSchemeMapper,
  defaultColors,
  defaultStyles,
} from '../../../stores/ThemingStore'
import bg from '../../bg1.png'

export default class JoinExpired extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      errorText: null,
    };

    this._signout = this.login.bind(this)
  }

  login() {
    window.location.href = '/login'
  }

  render() {
    const color_scheme = colorSchemeMapper['Light']

    return <ContainerWrapper color_scheme={color_scheme} {...defaultColors['Light']}>
      <Container>
        <Emoj><img src={ExpiredIcon} alt="expired meeting" /></Emoj>
        <Text>Your meeting link has expired</Text>
        <SubText>Please contact your administrator to request for a new meeting link.</SubText>
        <Button className="button primary" onClick={this.login}>
          Login
        </Button>
      </Container>
    </ContainerWrapper>



  }
}

const Button = styled.div`
  margin-top: 30px;
  font-weight: 400;
  cursor: pointer;
  width: 50%;
  text-align: center ;
`

const Emoj = styled.div`
  font-size: 72px;
`;
const Container = styled.div`
  color: #333;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: white;
  padding: 48px;
  border-radius: 4px;
  box-shadow: ${(props) => props.theme.shadows.light};
  min-width: 500px;
  max-height: 400px;
  height: 400px;
`;

const Text = styled.div`
  text-align: center;
  font-size: 18px;
  margin-top: 24px;
  margin-bottom: 8px;
  font-weight: 500;
`;

const SubText = styled.div`
  opacity: 0.6;
  max-width: 320px;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
`
const ContainerWrapper = styled.div`
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #363B45;
      position: fixed;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      ${Object.entries(defaultStyles || {})
    .map(([key, val]) => `${key} {${val}}`)
    .join('\n')}

      ${({ color_scheme }) => {
    const styleobj = Object.entries(color_scheme || {})
      .map(([key, val]) => `${key} {${val}}`)
      .join('\n')
    return styleobj
  }}
      ${props => props.loading ? 'z-index: 4;' : ''}
      background: ${props => props.loading ? '#fff' : ''};

      ${props => !props.loading ? `
            background: url(${bg}) no-repeat center 70% fixed; 
            -webkit-background-size: cover;
            -moz-background-size: cover;
            -o-background-size: cover;
            background-size: cover;
      ` : ''}
`
