import React, { PureComponent } from 'react'
import styled from 'styled-components'
import ErrorIcon from './500.png'

export default class Error extends PureComponent {
	render() {
		return (
			<Container>
				<div><img src={ErrorIcon}/></div>
				<Text>Something went wrong!</Text>
				<div>The room might have been archived, please reload the page or choose another room.</div>
				<div><button className="button primary" onClick={()=>window.location.reload()}>Reload</button></div>
			</Container>
		)
	}
}

const Container = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	font-size: 20px;

	button {
		cursor: pointer;
		font-size: 20px;
		border: 0px;
		margin-top: 28px;
		padding: 15px 80px !important;
		background: linear-gradient(90deg,#FF9900 0%,#ffc487 100%) !important;
	}
`

const Text = styled.div`
	text-align: center;
	font-size: 20px;
	color: #000;
	margin-top: 16px;
	margin-bottom: 8px;
	font-weight: 600;
`
