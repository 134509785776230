import React, { Component } from 'react'
import styled from 'styled-components'
import Modal from '/components/Modalv2'

export class TaskDeleted extends Component {

    constructor(props) {
        super(props)

        this.Ok = this.Ok.bind(this)
    }


    Ok() {
        this.props.closeModal()
    }

    render() {
        return (
            <Modal closeModal={this.props.closeModal} dismissible={true}>
                <Container >
                    <Contents>
                        <Title className="header">Task does not exist</Title>
                        <Details className="subheader">This task is already deleted.</Details>
                    </Contents>

                    <Options>
                        <Button className="button primary" onClick={this.Ok}>Ok</Button>
                    </Options>
                </Container>
            </Modal>
        )
    }
}

const Container = styled.div`
	padding: 15px 60px;
	display: flex;
	flex-direction: column;
	align-items: center;
    max-width: 400px;
`

const Contents = styled.div`
	display: flex;
	flex-direction: column;
	padding: 20px;
`

const Title = styled.div`
	font-weight: ${props => props.theme.textBold};
	font-size: ${props => props.theme.textLG};
`

const Details = styled.div`
	font-weight: ${props => props.theme.textRegular};
	font-size: ${props => props.theme.textMD};
	padding-top: 10px;
`

const Options = styled.div`
	display: flex;
	width: 70%;
`

const Button = styled.div`
	cursor: pointer;
	display: flex;
	justify-content: center;
	flex: 1;
	padding: 14px;
	margin: 4px;
`


export default TaskDeleted
