import React from 'react'
import Reflux from 'reflux'
import styled from 'styled-components'
import Color from 'color'
import moment from 'moment'
import { FiX as CloseIcon } from '@react-icons/all-files/fi/FiX'
import { FaLock as LockedIcon } from '@react-icons/all-files/fa/FaLock'
import { IoIosUnlock as UnlockedIcon } from '@react-icons/all-files/io/IoIosUnlock'
// import { FaTrash as TrashIcon } from '@react-icons/all-files/fa/FaTrash'

import { TiDelete as TrashIcon } from '@react-icons/all-files/ti/TiDelete'
import { FiMoreHorizontal as MoreOptionsIcon } from '@react-icons/all-files/fi/FiMoreHorizontal'
import { CgArrowsExpandUpLeft as NewIcon } from '@react-icons/all-files/cg/CgArrowsExpandUpLeft'

import { MainStore, WidgetActions } from '/stores/MainStore'
import NotesContent from './NotesContent'
import Modal from '/components/Modalv2'
import DropdownWrapper from '/screens/Meeting2.0/Sidebar/TopBar/DropdownWrapper'
import { ThemingStore } from '../../../../../stores/ThemingStore'
import LinkInput from './LinkInput';
import SyncModal from '../../../../../../src/loop-widgets/_Shared/SyncModal'
import { CgArrowsExpandUpLeft as Expand } from '@react-icons/all-files/cg/CgArrowsExpandUpLeft'
import CustomTip from '../../../../../helpers/CustomTip'
import { VariableCallingStore } from '../../../../../stores/VariableCallingStore'
import { CallingInstanceState } from '../../../../../calling/types'


const youtube_regex = /(youtube\.com\/watch\?.*v=|youtu\.be\/)([a-zA-Z0-9_-]+)/
const getYouTubeVideoId = (try_string) => {
    const match = try_string.match(youtube_regex)
    if (match && match.length > 1) {
        return match[2]
    }
    return try_string
}

class FloatingLinks extends Reflux.PureComponent {
    constructor(props) {
        super(props)

        this.stores = [MainStore, ThemingStore, VariableCallingStore]
        this.storeKeys = [
            'widgets', 'users', 'color_scheme', 'status'
        ]

        this.state = {
            activeNote: null,
            deleteData: null,
            deleteOpen: false,
            widgets: {
                links: {
                    links: []
                }
            },
            delModal: false,
            selectedLink: null,
            dupModal: false,
            duplicateLink: {},
            invalidModal: false
        }
        window.widgetaction = WidgetActions;
        this._setActiveNote = this._setActiveNote.bind(this)
        this._createTemplateNote = this._createTemplateNote.bind(this)
        this._handleOpenDelete = this._handleOpenDelete.bind(this)
        this._handleDeleteNote = this._handleDeleteNote.bind(this)
        this._handleNotePrivacy = this._handleNotePrivacy.bind(this)
    }

    componentDidUpdate(prevProps, prevState) {
        const { links = {} } = this.state.widgets
        const { links: prevLinks } = prevState.widgets
        const activeNote = (links.notes || []).filter(note => note.id === this.state.activeNote)

    }


    _setActiveNote(activeNote) {
        this.setState({ activeNote })
    }

    _createTemplateNote() {
        const id = `${moment().format("MMDDYYYYhhmmssA")}-${Math.floor((Math.random() * 100000000) + 1)}`
        const { notes = {} } = this.state.widgets
        const myUser =
            this.state.users.find(
                u => u.id === Reflux.GlobalState.auth.jwt.data._id
            ) || {}

        const data = {
            id,
            date_created: moment().format(),
            created_by: myUser.id || null,
            date_updated: null,
            updated_by: null,
            title: '',
            pureText: '',
            content: ' ',
            private: true
        }

        const newNotes = [data, ...(notes.notes || [])]

        this.props._updateSelf({
            notes: newNotes,
        })
    }

    _handleOpenDelete(deleteData, e) {
        e.stopPropagation()

        this.setState((prevState) => ({ deleteOpen: !prevState.deleteOpen, deleteData }))
    }

    _handleDeleteNote(deleteData, e) {
        e.stopPropagation()
        const { links = {} } = this.state.widgets
        let otherLinks = links.links
        let active = links.active
        let activeIndex = {
            id: active
        }

        if (deleteData) {
            const { timestamp, idx } = deleteData

            otherLinks = (links.links || []).filter((link) => link.timestamp !== timestamp)
            const publicNote = links.links || [];
            activeIndex = publicNote.length === idx + 1 ? publicNote[idx - 1] : publicNote[idx + 1]
        }

        this.props._updateSelf({
            links: otherLinks,
            active: activeIndex && activeIndex.timestamp ? activeIndex.timestamp : null
        })
        this.setState({ activeNote: null })
    }

    _handleNotePrivacy(id, e) {
        e.stopPropagation()
        const { notes = {} } = this.state.widgets
        const date_updated = moment().format()
        const currentNote = (notes.notes || []).find((note) => note.id === id)
        const otherNotes = (notes.notes || []).filter((note) => note.id !== id)

        const data = {
            ...currentNote,
            date_updated,
            updated_by: this.props.userId || null,
            private: !currentNote.private
        }

        let activeIndex = {
            id: notes.active
        }

        if (!!!notes.active) {
            activeIndex = {
                id
            }
        }

        const newNotes = [data, ...otherNotes]

        this.props._updateSelf({
            notes: newNotes,
            active: activeIndex.id || null,
        })

    }

    toggleDeleteModal = (link) => {
        this.setState({
            delModal: link ? true : false,
            selectedLink: link
        })
    }

	toggleDuplicateModal = ({val, link}) => {
		this.setState({dupModal: val})

		if(!!link) {
			this.setState({duplicateLink: link})
		}
	}

    toggleInvalidModal = ({val}) => {
		this.setState({invalidModal: val})
	}

    handleCallback = ({action, data}) => {
		if(action === 'duplicate') {
			this.toggleDuplicateModal(data)
		} else if(action === 'invalid') {
			this.toggleInvalidModal({val: true})
		}
	}

    showDuplicate = () => {
        try {
            let elem = Array.from(document.getElementsByClassName(`link-${this.state.duplicateLink.timestamp}`))[0]
            elem.scrollIntoView({
                block: 'center',
                behavior: 'smooth'
            })
            elem.classList.add('selected-link');

            setTimeout(() => {
                elem.classList.remove('selected-link');
                this.setState({ duplicateLink: {} })
            }, 3000);

        } catch (e) {
            console.log('error', e)
        }
    }

    onClickLink(e, link) {
        e.preventDefault()
        window.open(link.url, '_blank').focus()
    }

    transformLinks = () => {
		let links = this.state.widgets?.links?.links
        links = !links ? [] : !Array.isArray(links) ? Object.values(links) : links
        return links.length ? links.sort((a, b) => a.timestamp - b.timestamp) : links
	}

    render() {
        const { activeNote, widgets } = this.state
        const { links = {} } = widgets
        const myUser = this.state.users.find(u => u.id === Reflux.GlobalState.auth.jwt.data._id) || {}
        window.links = links;

        var checkDate = function (date) {
            if (typeof date !== 'object') {
                date = new Date(date);
            }

            var seconds = Math.floor((new Date() - date) / 1000)
            var intervalType

            var interval = Math.floor(seconds / 31536000);
            if (interval >= 1) {
                intervalType = 'y'
            } else {
                interval = Math.floor(seconds / 2592000)
                if (interval >= 1) {
                    intervalType = 'm'
                } else {
                    interval = Math.floor(seconds / 86400)
                    if (interval >= 1) {
                        intervalType = 'd'
                    } else {
                        interval = Math.floor(seconds / 3600)
                        if (interval >= 1) {
                            intervalType = "h"
                        } else {
                            interval = Math.floor(seconds / 60)
                            if (interval >= 1) {
                                intervalType = "m"
                            } else {
                                interval = seconds
                                if (interval >= 44) {
                                    intervalType = "s"
                                } else {
                                    intervalType = "now"
                                }
                            }
                        }
                    }
                }
            }

            if (interval < 44 && intervalType === 'now') {
                return intervalType
            }

            return interval + ' ' + intervalType
        }

        const yourNotes = this.transformLinks() || []
        return (
            <div>
                <Header className="border-light">
                    <HeaderLeft>Links</HeaderLeft>
                    <HeaderRight>
                        {/* <NewIcon size={20} onClick={() => this._createTemplateNote()} /> */}
                        <Expand className={this.state.status === CallingInstanceState.Connected ? 'disabled' : ''} size={20} onClick={() => {
                            if(this.state.status === CallingInstanceState.Connected) return
                            WidgetActions.ExpandFloatingWidgets({ currentWidget: 'links', localPush: true })
                            this.props._updateActiveSync('')
                        }
                        } data-tip data-for='expand-mini-link' />
                        <CloseIcon size={20} onClick={() => this.props._updateActiveSync('')} />
                    </HeaderRight>
                </Header>
                <CustomTip top={-20} left={-10} class='top-right' place='left' tip='Expand' dataFor='expand-mini-link' />
                <CustomTip top={-20} left={-10} class='top-right' place='left' tip='Remove Link' dataFor='delete-mini-link' />
                <Body className="BODY">
                    {
                        (yourNotes || []).length > 0 ?
                            yourNotes.map((note, idx) => {
                                return <NotePreview className={widgets.notes.active === note.id ? 'public-active' : `link-${note.timestamp}`} onClick={(e) => { e.stopPropagation(); this._setActiveNote(note.id); this.onClickLink(e, note) }} key={`note-preview-${idx}`} color={this.state.color_scheme === 'Light' ? '#F89809' : '#008BFF'}>
                                    <div className="preview-header">
                                        <div className="preview-title">
                                            {note.title}
                                        </div>
                                        <div>

                                            <TrashIcon size={18} onClick={(e) => {
                                                e.preventDefault()
                                                e.cancelBubble = true;
                                                if (e.stopPropagation) e.stopPropagation();
                                                this.toggleDeleteModal(note.url)

                                            }} data-tip data-for='delete-mini-link' />
                                        </div>
                                    </div>
                                    <div className="preview-content">
                                        <div className="preview-desc">
                                            {note.url}
                                        </div>
                                        <div className="preview-date">{checkDate(note.timestamp)}</div>
                                        {note.private ? <div className="private-flag"><LockedIcon size={9} /></div> : ''}
                                    </div>
                                </NotePreview>
                            }) : <div className="no-note">Your links is empty</div>}

                    <LinkInput addLink={this.props.addLink} handleCallback={this.handleCallback} />
                </Body>
                {!!this.state.delModal &&
                    <SyncModal
                        actionModal={() => this.props.removeLink(this.state.selectedLink)}
                        closeModal={() => {
                            this.toggleDeleteModal(null)
                        }}
                        type="removeLink"
                    />
                }
                {!!this.state.dupModal &&
                    <SyncModal
                        actionModal={() => this.showDuplicate()}
                        closeModal={() => this.toggleDuplicateModal({val: false})}
                        type="duplicateLink"
                    />
                }
                {this.state.invalidModal &&
                    <SyncModal
                        actionModal={() => {}}
                        closeModal={() => this.toggleInvalidModal({val: false})}
                        type="invalidLink"
                    />
                }
            </div>
        )
    }
}

const Dropdown = styled(DropdownWrapper)`
    display: flex;
    justify-content: center;
    align-items: center;
	cursor: pointer;
	margin: 4px 5px;
	position: relative;

    .dropdown-content {
        right: -10px;
    }
`

const Item = styled.div`
	display: flex;
	align-items: center;
	user-select: none;
	cursor: pointer;
	padding: 10px;
    border-radius: ${(props) => props.theme.borderRadius};
    font-size: 12px;
    font-weight: 400;

	& > svg {
		margin-right: 8px;
	}

	&:hover {
        color: ${(props) => props.theme.textPrimaryInvert};
        background-color: ${(props) => props.theme.hoverDarker};
        opacity: 0.85;
    }
`

export const NotePreview = styled.div`
    padding: 10px 30px 10px 30px;
    cursor: pointer;
    position: relative;

    &.public-active {
        background-color: ${props => Color(props.color || '#FF9900').alpha(0.2).toString()};
    }

    .preview-header {
        font-weight: ${props => props.theme.textBold};
        font-size: 14px;
        display: flex;
        align-items: center;

        .preview-title {
            text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
            flex: 1;
            margin-right: 5px;
            margin-bottom: 10px;
        }
    }

    .preview-content {
        display: flex;
        align-items: center;

        .preview-desc {
            font-size: 13px;
            text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
            flex: 1;
            margin-right: 10px;
        }

        .preview-date {
            font-size: 13px;
            opacity: 0.5;
            font-weight: ${props => props.theme.textRegular};
        }
    }

    .private-flag {
        position: absolute;
        left: 15px;
        top: 10px;
    }

    &.selected-link {
        background-color: rgba(0, 0, 0, 0.1);
    }
`

const Body = styled.div`
    padding-top: 15px;
    padding-bottom: 15px;
    overflow: auto;
    max-height: 390px;
    min-height: 390px;

    .no-note {
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 0.4;
        height: 410px;
        font-weight: 400;
    }

    @media (max-height: 740px) {
        min-height: 220px;
        max-height: 220px;

        .no-note {
            height: 280px;
        }
    }

    @media (max-width: 480px) {
        min-height: 280px;
        max-height: 280px;

        .no-note {
            height: 280px;
        }
    }
`

const Header = styled.div`
    border-bottom: 1px solid;
    padding: 15px;
    font-weight: 500;
    display: flex;
    align-items: center;
`

const HeaderLeft = styled.div`
    flex: 1;
`

const HeaderRight = styled.div`
    > * {
        margin-left: 10px;
        cursor: pointer;
    }

    .disabled {
        opacity: 0.3;
        pointer-events: none;
    }
`

export default FloatingLinks
