import React, { Component } from 'react'
import styled from 'styled-components'

import Modal from '/components/Modalv2'
const { GlobalState } = require('reflux')

export default class RemovedUser extends Component {
	constructor(props) {
		super(props)

		this.Ok = this.Ok.bind(this)
	}

	async Ok() {
		this.props.closeModal()
		this.props.history.push('/login')
		window.location.reload()
	}

	render() {
		return (
			<Modal closeModal={this.props.closeModal} dismissible={false}>
				<Container >
					<Contents>
						<Title className="header">You've been removed in <span className="color-primary">{`${GlobalState.main.db_meeting.name_alias} [${GlobalState.main.db_meeting.name}]`}</span></Title>
						<Details className="subheader">The room creator removed you from this room.</Details>
					</Contents>

					<Options>
						<Button className="button primary" onClick={this.Ok}>Ok</Button>
					</Options>
				</Container>
			</Modal>
		)
	}
}

const Container = styled.div`
	padding: 12px;
	display: flex;
	flex-direction: column;
	align-items: center;
    max-width: 400px;
`

const Contents = styled.div`
	display: flex;
	flex-direction: column;
	padding: 20px;
`

const Title = styled.div`
	font-weight: ${props => props.theme.textBold};
	font-size: ${props => props.theme.textLG};
`

const Details = styled.div`
	font-weight: ${props => props.theme.textRegular};
	font-size: ${props => props.theme.textMD};
	padding-top: 10px;
`

const Options = styled.div`
	display: flex;
	width: 70%;
`

const Button = styled.div`
	cursor: pointer;
	display: flex;
	justify-content: center;
	flex: 1;
	padding: 14px;
	margin: 4px;
`
