import React, { Component } from 'react'
import { GlobalState } from 'reflux'
import styled from 'styled-components'

import LoopApi from '/helpers/LoopApi'
import Modal from '/components/Modalv2'
import { ModalActions } from '../../../stores/ModalStore'

export default class RemoveUser extends Component {
	constructor(props) {
		super(props)

		this.Cancel = this.Cancel.bind(this)
		this.Remove = this.Remove.bind(this)
	}

	Cancel() {
		this.props.closeModal()
        ModalActions.SetModal('PublicLink')
	}

	Remove() {
		GlobalState.modal.cb && GlobalState.modal.cb()
		this.props.closeModal()
		ModalActions.SetModal('PublicLink')
	}

	render() {
		return (
			<Modal closeModal={this.props.closeModal}>
				<Container >
					<Contents>
						<Title className="header">Remove User</Title>
						{/* <Details className="subheader">Are you sure you want to remove <span className="color-primary" style={{ fontWeight: 500 }}>{GlobalState.modal.username}</span>? </Details> */}
						<Details className="subheader">Are you sure you want to remove this user/s? </Details>
					</Contents>

					<Options>
						<Button className="button default" onClick={this.Cancel}>No</Button>
						<Button className="button primary" onClick={this.Remove}>Yes</Button>
					</Options>
				</Container>
			</Modal>
		)
	}
}

const Container = styled.div`
	padding: 12px;
	display: flex;
	flex-direction: column;
	align-items: center;
`

const Contents = styled.div`
	display: flex;
	flex-direction: column;
	padding: 20px;
`

const Title = styled.div`
	font-weight: ${props => props.theme.textBold};
	font-size: ${props => props.theme.textLG};
`

const Details = styled.div`
	font-weight: ${props => props.theme.textRegular};
	font-size: ${props => props.theme.textMD};
	padding-top: 10px;
`

const Options = styled.div`
	display: flex;
	width: 70%;
`

const Button = styled.div`
	cursor: pointer;
	display: flex;
	justify-content: center;
	flex: 1;
	padding: 14px;
	margin: 4px;
`
