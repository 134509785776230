import React, { PureComponent } from "react";
import styled from "styled-components";
import ExpiredIcon from '../Meeting2.0/Setup/expired.svg'

import {
  defaultColors,
  defaultStyles,
} from '../../stores/ThemingStore'
import bg from '../../screens/bg1.png'

export default class UserArchived extends PureComponent {
  constructor(props) {
    super(props);
  }

  signin = () => {
    this.props.push('/login')
	}

  render() {
    return (
      <Container color_scheme={'Light'} {...defaultColors['Light']}>
        <Wrapper>
          <Emoj><img src={ExpiredIcon} alt="expired link" /></Emoj>
          <Text>This magic link cannot be used</Text>
          <SubText>This user is archived</SubText>
          <Button className="button primary" onClick={this.signin}>
            Go back to Login
          </Button>
        </Wrapper>
      </Container>
    );
  }
}

const Button = styled.div`
  margin-top: 30px;
  font-weight: 400;
  cursor: pointer;
`

const Emoj = styled.div`
  font-size: 72px;
`

const Wrapper = styled.div`
  color: #333;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: white;
  padding: 48px;
  border-radius: 4px;
  box-shadow: ${(props) => props.theme.shadows.light};
  min-width: 500px;
  max-height: 400px;
  height: 400px;
`

const Text = styled.div`
  text-align: center;
  font-size: 18px;
  margin-top: 24px;
  margin-bottom: 8px;
  font-weight: 500;
`

const SubText = styled.div`
  opacity: 0.6;
  max-width: 320px;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
`

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #363B45;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  ${Object.entries(defaultStyles || {})
        .map(([key, val]) => `${key} {${val}}`)
        .join('\n')}

  ${({ color_scheme }) => {
        const styleobj = Object.entries(color_scheme || {})
              .map(([key, val]) => `${key} {${val}}`)
              .join('\n')
        return styleobj
  }}

  background: url(${bg}) no-repeat center 70% fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
`