import React, { Component } from 'react'
import styled from 'styled-components'

import Modal from '/components/Modalv2'
import { FiInfo as InfoIcon } from '@react-icons/all-files/fi/FiInfo'
import {isMobile} from '../../../calling/_helpers'

var userAgent = navigator.userAgent.toLowerCase()
export default class PermissionNeeded extends Component {
  constructor(props) {
    super(props)

    this.state = {
      browserName: ''
    }

    this.Remove = this.Remove.bind(this)
  }

  componentDidMount() {
    let browserName = ''
    if(userAgent.match(/edg/i)){
      browserName = "edge";
    } else if(userAgent.match(/chrome|chromium|crios/i)){
      browserName = "chrome";
    } else if(userAgent.match(/firefox|fxios/i)){
      browserName = "firefox";
    } else if(userAgent.match(/safari/i)){
      browserName = "safari";
    } else if(userAgent.match(/opr\//i)){
      browserName = "opera";
    } else{
      browserName="No browser detection";
    }

    this.setState({ browserName })
  }

  Remove() {
    sessionStorage.setItem('safariPermissionAllowed', true)
    this.props.closeModal()
  }

  render() {

    return (
      <Modal closeModal={this.props.closeModal} dismissible={false}>
        <Container>
          <Icon className="container">
            <InfoIcon />
          </Icon>
					<Title>Allow Access</Title>
					<Info>
              Please update your  browser settings to allow 
              access to your camera and microphone.

					</Info>
          { this.state.browserName === 'safari' ? 
            <React.Fragment>
            {
              isMobile ? 
              <Info>
                If you don't want to see this prompt every time you will join a call using your mobile browser, please change your settings manually by following these instructions:
                <br />
                <div style={{textAlign: 'left', marginTop: '5px'}}>
                  1. Click "Settings"
                  <br />
                  2. Scroll down and tap "Safari"
                  <br />
                  3. Go to Camera and Microphone and change settings to "Allow"
                </div>
                <br />
                <div className='error'>Warning: This settings will be applied to all websites that you will visit.</div>
              </Info> :
              <Info>
                If you don't want to see this prompt every time you will join a call, please change your settings manually by following these instructions:
                <br />
                <div style={{textAlign: 'left', marginTop: '5px'}}>
                  1. Click "Safari" on the upper left hand
                  <br />
                  2. Choose Preferences
                  <br />
                  3. Go to Camera and Microphone and change settings to "Allow"
                </div>
              </Info>
            }
            
            </React.Fragment>
          : '' }
          <Options>
            <Button onClick={this.Remove} id="dismiss" className="button primary">Ok</Button>
          </Options>
				</Container>
      </Modal>
    )
  }
}

const Icon = styled.div`
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  color: inherit;
`

const Info = styled.div`
  font-weight: 400;
  color: inherit;
  line-height: 20px;
  margin: 10px 0 15px;
  opacity: 0.5;
`

const Container = styled.div`
	position: relative;
	text-align: center;
	padding: 30px;
	display: flex;
	align-items: center;
	flex-direction: column;
  max-width: 420px;
`

const Title = styled.div`
  font-size: 18px;
  font-weight: 500;
  margin: 15px 0;
  &::first-letter {
    text-transform: uppercase;
  }
`

const Options = styled.div`
  display: flex;
  width: 100%;
`

const Button = styled.div`
  cursor: pointer;
  display: flex;
  flex: 1;
  justify-content: center;
  padding: 14px;
  margin: 4px;
  font-weight: 400;
`
