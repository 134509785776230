import React from 'react';
import styled from 'styled-components';

import Modal from '/components/Modalv2';
import { FiInfo as InfoIcon } from '@react-icons/all-files/fi/FiInfo';
import { functions } from 'lodash';
import { AiOutlineCreditCard } from 'react-icons/ai';
import LoopApi from '../../../helpers/LoopApi'
import { GlobalState } from 'reflux'
import { ModalActions } from '../../../stores/ModalStore';

const ChangePaymentMethod = (props) => {
    const [name, setName] = React.useState('');
    const [card, setCard] = React.useState('');
    const [month, setMonth] = React.useState('');
    const [year, setYear] = React.useState('');
    const [cvv, setCvv] = React.useState('');
    const [btnLoad, setBtnLoad] = React.useState(false);
    const [error, setError] = React.useState(null);

    React.useEffect(() => {
        //console.log(GlobalState)
        if(month.length > 2) setMonth(month.slice(0, -1))
        if(year.length > 4) setYear(year.slice(0, -1))
    }, [month, year])

    function Remove() {
        props.closeModal();
    }

    const changeBillingDetails = async () => {
        setBtnLoad(true)
        try {
            await LoopApi(null, 'StripeChangeBillingDetails', { name, card, month, year, cvv, subscription: GlobalState.auth.jwt.data.subscription })
            ModalActions.SetModal('ChangePaymentMethodSuccess')
            setBtnLoad(false)
        } catch ({ error }) {
            setError(error)
            setBtnLoad(false)
        }
    }

    const onNameChange = (event) => {
        setName(event.target.value);
    };

    const onCardNumberChange = (event) => {
        setCard(event.target.value);
    };

    const onMonthChange = (event) => {
        if(!event.target.value) setMonth('');
        const nan = isNaN(parseInt(event.target.value))
        if(nan) return // if input is not a number
        setMonth(event.target.value);
    };

    const onYearChange = (event) => {
        if(!event.target.value) setYear('');
        const nan = isNaN(parseInt(event.target.value))
        if(nan) return // if input is not a number
        setYear(event.target.value);
    };

    const onCvvChange = (event) => {
        setCvv(event.target.value);
    };

    return (
        <Modal closeModal={props.closeModal} dismissible={true}>
            <Container>
                <Title>Change Billing Details</Title>
                <div className='card-row'>
                    <input className='topbar border-light' placeholder='Name (Unchanged)' value={name} onChange={onNameChange} />
                </div>
                <div className='card-row'>
                    <input className='topbar border-light' placeholder='Card Number: 1234 5678 1234 5678' value={card} onChange={onCardNumberChange} />{' '}
                    <AiOutlineCreditCard size={45} />
                </div>
                <div className='card-row'>
                    <input className='topbar border-light' placeholder='Month: 12' value={month} onChange={onMonthChange} />
                    <input className='topbar border-light' placeholder='Year: 26' value={year} onChange={onYearChange} />
                    <input className='topbar border-light' placeholder='CVV/CVC' value={cvv} onChange={onCvvChange} />
                </div>
                <div className='error'>
                    {error || null}
                </div>
                <Options>
                    <Button onClick={changeBillingDetails} id='dismiss' className='button primary' disabled={btnLoad}>
                        {btnLoad ? "Updating..." : "Update"}
                    </Button>
                </Options>
            </Container>
        </Modal>
    );
};

export default ChangePaymentMethod;

const Container = styled.div`
    position: relative;
    text-align: center;
    padding: 30px;
    display: flex;
    align-items: center;
    flex-direction: column;
    max-width: 420px;

    .error {
        color: red
    }

    input {
        /* White */

        /* background: #ffffff; */
        /* Grey - Med */

        /* border: 1px solid #e9ecef; */
        border: 1px solid;
        box-sizing: border-box;
        border-radius: 5px;
        width: 100%;
    }

    .card-row {
        display: flex;
        width: 100%;
        margin: 5px;
    }
`;

const Title = styled.div`
    font-size: 18px;
    font-weight: 500;
    margin: 15px 0;
    &::first-letter {
        text-transform: uppercase;
    }
`;

const Options = styled.div`
    display: flex;
    width: 100%;
`;

const Button = styled.button`
    cursor: pointer;
    display: flex;
    flex: 1;
    justify-content: center;
    padding: 14px;
    margin: 4px;
    font-weight: 400;
    border: 0;
`;
