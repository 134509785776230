import React, { PureComponent } from 'react'
import styled from 'styled-components'
import { FaCircleNotch as CircleSpinner } from '@react-icons/all-files/fa/FaCircleNotch'

export default class ConnectionProblemOverlays extends PureComponent {
	render() {
		if (this.props.connection_state === 'Reconnecting') {
			return (
				<Reconnecting key="Reconnecting">
					<CircleSpinner size={36} className="icon-spin" />
					<MediumText>Reconnecting</MediumText>
					<SmallText>Looks like you lost your connection. Trying to reconnect.</SmallText>
				</Reconnecting>
			)
		} else if (this.props.connection_state === 'Unreachable') {
			return (
				<Reconnecting key="Reconnecting">
					<MediumText>Could not Connect</MediumText>
					<SmallText>Try a different room or reloading the page</SmallText>
				</Reconnecting>
			)
		}

		return null
	}
}

const Reconnecting = styled.div`
	max-width: 100% !important;
	height: 100% !important;
	background-color: rgba(0, 0, 0, 0.6);
	color: white;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: 2;
`
const MediumText = styled.div`
	font-size: 24px;
	font-weight: 500;
	margin: 12px;
`
const SmallText = styled.div`
	font-size: 16px;
`
