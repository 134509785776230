import React, { PureComponent } from 'react'
import styled from 'styled-components'

export default class TopBar extends PureComponent {
	render() {
		let name = this.props.nameOverride
			? `${this.props.nameOverride} (${this.props.displayName})`
			: this.props.displayName
		return (
			<Bar
				draggable="true"
				ref={this.props.renderedRef}
				currentIsMinimized={this.props.currentIsMinimized}
			>
				<Icon src={this.props.icon} />
				{/* <Name>{name}</Name> */}
				<DragZone />
			</Bar>
		)
	}
}

const DragZone = styled.div`
	position: absolute;
	left: 0;
	bottom: 72px;
	right: 0;
	width: 36px;
	z-index: 1;
`

const Icon = styled.img`
	/* height: 36px; */
	width: 25px;
	margin-right: 8px;
`

const Name = styled.p`
	font-size: 1.2rem;
	margin-left: 6px;
	font-weight: 500;
	
	@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:portrait) {
		font-size: 14px;
		/* For portrait layouts only */
	}

`
const Bar = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 0 4px;

	background: none;

	${(props) =>
		props.theme.bot_mode &&
		(!props.theme.presentation.topBar ||
			props.theme.presentation.presentationStyle === 'fullscreen') &&
		`display: none;`};

	${(props) => props.currentIsMinimized && 'height: max-content;'}
`
