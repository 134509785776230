import React, { Component } from 'react'
import { GlobalState } from 'reflux'
import styled from 'styled-components'

import LoopApi from '/helpers/LoopApi'
import Modal from '/components/Modalv2'
import moment from 'moment'

export default class RenameTranscription extends Component {
	constructor(props) {
		super(props)

		this.Cancel = this.Cancel.bind(this)
		this.Leave = this.Leave.bind(this)
		this.Request = this.Request.bind(this)
		this._onChange = this._onChange.bind(this)

		
		this.state = {
			name: null,
			description: null,
            created_at: null
		  };
	}

	Cancel() {
		this.props.closeModal()
	}

	async Leave() { 
		this.props.closeModal()
	}

	async Request(e) {
		e.preventDefault();
        const { getTranscriptions, view } = GlobalState.modal 
		await LoopApi(null, 'RenameTranscription', { view, name: this.state.name || view.title, description: this.state.description || view.description }) 
		await getTranscriptions();
		this.props.closeModal()
	}

	_onChange(event) {
		const { target } = event;
		const { value, name } = target;
	
		this.setState({
		  [name]: value,
		});
	  }

	componentDidMount() {
		const { title, description, created_at } = this.props.meeting.widgets.transcription.view;
		this.setState({ name: title, description, created_at })
	}

	render() {
        const { view } = GlobalState.modal 

		return (
			<Modal closeModal={this.props.closeModal}>
				<Container >
					<Contents>
						<Title className="header">Rename</Title>
						
						<Form onSubmit={this.Request}>
							<Field
								className="border-light topbar"
								name='name'
								type='text'
								placeholder={this.state.name || moment(this.state.created_at).format('lll')}
								onChange={this._onChange}
								value={this.state.name}
                                defaultValue={view.title}
							/> 
							<Field
								className="border-light topbar"
								name='description'
								type='text'
								placeholder='New Description' 
								onChange={this._onChange}
								value={this.state.description}
                                defaultValue={view.description}
							/> 
					</Form>
					</Contents>

					<Options>
						<Button className="button primary" onClick={this.Request}>Save</Button>
					</Options>
				</Container>
			</Modal>
		)
	}
}

const Container = styled.div`
	padding: 12px;
	display: flex;
	flex-direction: column;
	align-items: center;
`

const Contents = styled.div`
	display: flex;
	flex-direction: column;
	padding: 20px;
`

const Title = styled.div`
	font-weight: ${props => props.theme.textBold};
	font-size: ${props => props.theme.textLG};
`

// const Details = styled.div`
// 	font-weight: ${props => props.theme.textRegular};
// 	font-size: ${props => props.theme.textMD};
// 	padding-top: 10px;
// `

const Options = styled.div`
	display: flex;
	width: 70%;
`

const Button = styled.div`
	cursor: pointer;
	display: flex;
	justify-content: center;
	flex: 1;
	padding: 14px;
	margin: 4px;
`


const Field = styled.input`
  flex: 1;
  padding-left: 16px;
  background-color: transparent;
  width: 90%;
  margin: 10px;
  border: 1px solid;
  outline: none;
  border-radius: 999px;

  &::placeholder {
    opacity: 0.5;
  }
`;
const Form = styled.form` 
`;
