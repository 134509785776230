import React, { PureComponent } from 'react'
import styled from 'styled-components'
import { FaAngleRight as ExpandIcon } from '@react-icons/all-files/fa/FaAngleRight'
import { FaAngleLeft as CollapseIcon } from '@react-icons/all-files/fa/FaAngleLeft'

import { ModalActions } from '/stores/ModalStore'


export default class WidgetAdder extends PureComponent {
	render() {
		const { expanded, toggleSidebar = () => {}, landscapeCollapse, disabled } = this.props
		return (
			<MainCont className="container" landscapeCollapse={landscapeCollapse}>
				<Container
					className={`button default ${disabled ? 'disabled' : ''}`}
					onClick={disabled ? () => {} : () => ModalActions.SetModal('AddWidget')}
					landscapeCollapse={landscapeCollapse}
				>
					<div>Add Sync</div>
				</Container>
				{/* <ToggleList title={expanded ? 'Collapse' : 'Expand'} expanded={expanded} onClick={toggleSidebar} className="border focus topbar">
					{expanded ? <CollapseIcon size={16} /> : <ExpandIcon size={16} />}
				</ToggleList> */}
			</MainCont>
		)
	}
}

const MainCont = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;

	@media (max-width: 970px),
	(max-width: 900px) and (orientation: landscape) {
		display: none;
	}

	/* @media (max-width: 970px) {
		width: 130px;
		min-width: 130px;
		height: 120px;
		position: fixed;
		z-index: 2;
		margin-left: -10px;
	}

	*/
	@media (min-width: 970px) {
		display: none;
	} 
`

const ToggleList = styled.div`
	border-radius: 999px;
	width: 26px;
	height: 26px;
	display: flex;
	justify-content: center;
	align-items: center;
    z-index: 2;
	border: 2px solid;
	cursor: pointer;
	transition: 0.3s left ease-in-out;

	${props => !props.expanded ? `
		position: absolute;
		left: 0px;
		border-radius: 0px 50% 50% 0;
	` : ''}

`

const Container = styled.div`
	position: relative;
	flex-grow: 1;
	margin: 0 12px 24px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	/* padding: 0px !important; */
	
	&:hover {
		box-shadow: ${props => props.theme.shadows.newlight};
	}

	@media (max-width: 970px),
	(max-width: 900px) and (orientation: landscape) {
		display: none;
	}

	@media (max-width: 970px) {
		max-width: 120px;
		min-width: 120px;
		min-height: 110px;
		height: 110px;
		max-height: 110px;
		position: fixed;
		left: 15px;
		margin: 0 15px;
		border-radius: 15px !important;
		z-index: 2;
	}

	// mobile
	/* @media (max-width: 480px) {
		max-width: 150px;
		min-height: 150px;
		height: 150px;
		max-height: 150px;
		left: 10px;
	} */

`

const Internal = styled.div`
	transition: all 0.3s ease-in-out;
	display: flex;
	flex-direction: row;
	flex: 1;
	align-items: center;
	justify-content: center;
	padding: 16px;
	font-size: 16px;
	font-weight: 400;
	border-radius: inherit;
	> * {
		margin-right: 4px;
		margin-left: 4px;
	}

	cursor: pointer;
	&:hover {
		box-shadow: ${(props) => props.theme.shadows.heavy};
		opacity: 0.85;
	}

	@media (max-width: 970px) {
		max-width: 150px;
		min-height: 115px;
		height: 115px;
		max-height: 115px;
	}
`
