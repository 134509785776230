import moment from 'moment'
import Reflux from 'reflux'
import React, { PureComponent } from 'react'
import styled from 'styled-components'
import { ThemingStore } from '../../../../stores/ThemingStore'
import { BiTimeFive as TimeIcon } from '@react-icons/all-files/bi/BiTimeFive'
export default class CallTimer extends Reflux.PureComponent {
	constructor(props) {
		super(props)


		this.stores = [ThemingStore]
		this.storeKeys = ['color_scheme']

		this.state = {
			timeSinceStart: 0,
		}
		this.interval = null
	}

	componentDidMount() {
		this.resetInterval()
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.startTime !== this.props.startTime) {
			this.resetInterval()
		}
	}

	resetInterval() {
		clearInterval(this.interval)
		this.interval = setInterval(() => {
			const then = moment.unix(this.props.startTime).format("DD/MM/YYYY HH:mm:ss.SSS")
			const now = moment().format()
			const timeSinceStart = moment().diff(moment(then, "DD/MM/YYYY HH:mm:ss.SSS"));

			return !this.props.stopped &&
			this.setState({
				timeSinceStart
			})
		}, 1000);
	}

	componentWillUnmount() {
		clearInterval(this.interval)
		this.interval = null
	}

	render() {
		const duration = moment.duration(this.state.timeSinceStart);
		const timer = Math.floor(duration.asHours()) + moment.utc(this.state.timeSinceStart).format(":mm:ss");
		return (
			<DurationWrapper isLight={this.state.color_scheme === 'Light'} mobile={this.props.mobile}>
				{/* <RecorderWrapper className="container content">
					<Recorder />
				</RecorderWrapper> */}
				<RecorderWrapper>
					<TimeIcon />
				</RecorderWrapper>
				{timer}
			</DurationWrapper>
		)
	}
}

const DurationWrapper = styled.div`
	display: flex;
	align-items: center;
	font-weight: 400; 
    width: 170px;
    justify-content: center;
	transition: 0.3s all ease-in-out;
	margin-top: 20px;

	${props => props.mobile ? 'display: none;' : ''}

	@media (max-width: 970px) {
		position: fixed;
		z-index: 3;
		width: auto;
		padding: 5px 10px;
		border-radius: 999px;
		min-width: 100px;
		box-shadow: ${props => props.theme.shadows.newlight};
		top: 80px;
		left: 0;
		right: 0;
		max-width: 100px;
		margin: 0 auto;
		color: #fff;	
		background: ${props => props.isLight ? '#FF9900' : '#008BFF'};
		display: flex;
	}
`

const RecorderWrapper = styled.div`
	width: 24px;
	height: 24px;
	border-radius: 999px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 5px;
`

const Recorder = styled.div`
	width: 10px;
	height: 10px;
	border-radius: 999px;
	background-color: #FF3C3C;
`