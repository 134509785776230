import React from 'react'
import Reflux from 'reflux'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'

import Loading from './Loading'
import CreateOrJoin from './CreateOrJoin'
import ErrorComp from './ErrorComp'
import Password from './Password'
import Knock from './Knock'
import NotFound from './NotFound'
import Unauthorized from './Unauthorized'
import Locked from './Locked'
import { MainStore } from '/stores/MainStore'
import {
      colorSchemeMapper,
      defaultColors,
      defaultStyles,
      ThemingStore,
} from '../../../stores/ThemingStore'
import Expired from './Expired'
import bg from '../../bg1.png'
import Archived from './Archived'

class Setup extends Reflux.PureComponent {
      constructor(props) {
            super(props)
            this.stores = [MainStore, ThemingStore]
            this.storeKeys = ['db_meeting', 'setupPhase', 'color_scheme']
            this._forceGhostPassword = this._forceGhostPassword.bind(this)
      }

      _forceGhostPassword() {
            return (
                  this.state.setupPhase !== 'complete' &&
                  this.state.db_meeting.ghost_mode &&
                  (!sessionStorage.ghost_hash ||
                        sessionStorage.ghost_meeting !==
                              this.props.match.params.meetingName)
            )
      }

      render() {
            const phase = this._forceGhostPassword()
                  ? 'password-ghost'
                  : this.state.setupPhase
            const newProps = {
                  ...this.props,
                  phase,
                  location: this.props.location,
                  push: this.props.history.push,
            }
            const color_scheme = colorSchemeMapper['Light']

            let setupComp = null
            switch (phase) {
                case 'create-or-join':
                    setupComp = <CreateOrJoin {...newProps} noTheme />;
                    break;
                case 'loading':
                case 'awaiting-meeting':
                    setupComp = <Loading awaiting={phase === 'awaiting-meeting'} />;
                    break;
                case 'password':
                case 'password-join-ghost':
                case 'password-ghost':
                    setupComp = <Password {...newProps} case={phase} />;
                    break;
                case 'expired':
                    setupComp = <Expired {...newProps} />;
                    break;

                case 'knock':
                    setupComp = <Knock {...newProps} />;
                    break;
                case 'error':
                    setupComp = <ErrorComp />;
                    break;
                case 'notfound':
                    setupComp = <NotFound {...newProps} />;
                    break;
                case 'unauthorized':
                    setupComp = <Unauthorized />;
                    break;
                case 'locked':
                    setupComp = <Locked {...newProps} />;
                    break;
                case 'archived':
                    setupComp = <Archived />;
                    break;
                default:
                    return null;
            }

            return <Container loading={phase === 'loading' || phase === 'awaiting-meeting'} color_scheme={color_scheme} {...defaultColors['Light']}>{setupComp}</Container>
      }
}
export default withRouter(Setup)

const Container = styled.div`
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #363B45;
      position: fixed;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      ${Object.entries(defaultStyles || {})
            .map(([key, val]) => `${key} {${val}}`)
            .join('\n')}

      ${({ color_scheme }) => {
            const styleobj = Object.entries(color_scheme || {})
                  .map(([key, val]) => `${key} {${val}}`)
                  .join('\n')
            return styleobj
      }}
      ${props => props.loading ? 'z-index: 4;' : ''}
      background: ${props => props.loading ? '#fff' : ''};

      ${props => !props.loading ? `
            background: url(${bg}) no-repeat center 70% fixed; 
            -webkit-background-size: cover;
            -moz-background-size: cover;
            -o-background-size: cover;
            background-size: cover;
      ` : ''}
`
