import React, { Component } from 'react'
import styled from 'styled-components'

import Modal from '/components/Modal'
import LoopApi from '/helpers/LoopApi'

export default class SendVerificationEmail extends Component {
  constructor(props) {
    super(props)

    this.state = { sent: false, email: '', errorMessage: null, loading: false }
  }

  handleChange(field, event) {
    const change = {}
    change[field] = event.target.value
    this.setState(change)
  }

  async handleSubmit(event) {
    event.preventDefault()
    if (!this.state.email) {
      return
    }

    this.setState({ loading: true, errorMessage: null })

    try {
      const resp = await LoopApi(null, 'VerifyMe', {
        email: this.state.email,
      })

      if (resp.error) {
        this.setState({
          loading: false,
          errorMessage: resp.msg || '',
        })
      } else {
        //console.log(resp, 'good')
        this.setState({ sent: true })
      }
    } catch (err) {
      console.error(err)
      this.setState({ loading: false, errorMessage: err.msg })
    }
  }

  render() {
    if (this.state.sent) {
      return (
        <Modal closeModal={this.props.closeModal}>
          <SendForm>We've sent you an email to confirm your account</SendForm>
        </Modal>
      )
    }

    return (
      <Modal closeModal={this.props.closeModal}>
        <SendForm onSubmit={this.handleSubmit.bind(this)}>
          <div>Confirm your account:</div>
          <FormField
            data-lpignore="true"
            type="email"
            placeholder="Email"
            value={this.state.email}
            onChange={this.handleChange.bind(this, 'email')}
          />
          <SubmitButton
            disabled={this.state.loading}
            value="Verify"
            type="submit"
          />
          {this.state.errorMessage && (
            <ErrorMsg>{this.state.errorMessage}</ErrorMsg>
          )}
        </SendForm>
      </Modal>
    )
  }
}

const SendForm = styled.form`
  padding: 20px;
  display: flex;
  flex-direction: column;
`

const FormField = styled.input`
  padding: 4px 8px;
  font-size: 16px;
  border: none;
  border-bottom: 1px solid #333;
  &:-webkit-autofill {
    background-color: white !important;
  }
  margin-top: 12px;
`

const SubmitButton = styled.input`
  margin-top: 12px;
  background-color: ${props => props.theme.color.rotate(90).string()};
  border-radius: 0;
  border: none;
  font-size: 14px;
  box-shadow: ${props => props.theme.shadows.light};
  color: white;
  padding: 9px;
  cursor: pointer;

  &:disabled {
    opacity: 0.5;
    cursor: initial;
    pointer-events: none;
  }

  &:hover {
    opacity: 0.9;
  }
`

const ErrorMsg = styled.div`
  color: rgb(200, 50, 50);
  font-size: 12px;
  text-align: center;
`
