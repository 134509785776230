import React, { memo } from 'react';
import LoopApi from '../../helpers/LoopApi';

export const Upgrade = memo(() => {
    React.useEffect(() => {
        getMyInfo();
    }, []);

    const getMyInfo = async () => {
        const me = await LoopApi(null, 'Me');
        localStorage.setItem('token', me.token.token)
        window.location.href = `/${localStorage.getItem('lastMeeting')}`
    };

    return <>Upgrading plan... please wait...</>;
});
