import React from 'react'
import Reflux from 'reflux'
import styled from 'styled-components'

import { ModalStore } from '/stores/ModalStore'
import Modal from '/components/Modalv2'
// import ArrowUpIcon from '../../assets/icons/arrow_up.svg'
import { MdBlock as BlockIcon } from '@react-icons/all-files/md/MdBlock'

export default class PermissionBlocked extends Reflux.Component {
  constructor(props) {
    super(props)

    this.Remove = this.Remove.bind(this)

    this.store = ModalStore
		this.storeKeys = ['denied']

  }

  Remove() {
    this.props.closeModal()
  }

  render() {
    const { denied = '' } = this.state
    const whichOne = denied === 'audio' ?
      'microphone' : denied === 'video' ?
      'camera' : 'camera and microphone'

    return (
      <Modal closeModal={this.props.closeModal} dismissible={false}>
        <Container>
          <Icon className="container">
            <BlockIcon />
          </Icon>
					<Title>Permission Blocked</Title>
					<Info>
              Please update your  browser settings to allow 
              access to your {whichOne}.
					</Info>
          <Options>
            <Button onClick={this.Remove} id="dismiss" className="button primary">Ok</Button>
          </Options>
				</Container>
      </Modal>
    )
  }
}

const Icon = styled.div`
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  color: inherit;
`

const Info = styled.div`
  font-weight: 400;
  color: inherit;
  line-height: 20px;
  margin: 10px 0 15px;
  opacity: 0.5;
`

const Container = styled.div`
	position: relative;
	text-align: center;
	padding: 30px;
	display: flex;
	align-items: center;
	flex-direction: column;
  max-width: 420px;
`

const Title = styled.div`
  font-size: 18px;
  font-weight: 500;
  margin: 15px 0;
  &::first-letter {
    text-transform: uppercase;
  }
`

const Options = styled.div`
  display: flex;
  width: 100%;
`

const Button = styled.div`
  cursor: pointer;
  display: flex;
  flex: 1;
  justify-content: center;
  padding: 14px;
  margin: 4px;
  font-weight: 400;
`
