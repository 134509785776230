import React from 'react'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import { CallingInstanceState } from '../../../calling/types'

import { CallingProviderName } from '../../../contexts/types'
import useMeetingContext from '../../../contexts/useMeetingContext'

import Modal from '/components/Modalv2'

function ConfirmLogout(props) {
	const { callProvider, room } = useMeetingContext()

	const Cancel = () => {
		props.closeModal()
	}

	const Yes = () => {
		localStorage.removeItem('token')
		sessionStorage.removeItem('roomsAllowed')
		localStorage.removeItem('recentRooms')
        if(localStorage.getItem('audio_input_device_id')) {
			localStorage.removeItem('audio_input_device_id')
		}
		if(localStorage.getItem('video_input_device_id')) {
			localStorage.removeItem('video_input_device_id')
		}
		if(localStorage.getItem('audio_output_device_id')) {
			localStorage.removeItem('audio_output_device_id')
		}

        props.history.push('/login')
		if(room) {
			if(callProvider === CallingProviderName.DailyCo) {
				room.leave();
			} else {
				room.disconnect();
			}
		}

		toast.dismiss('meetingRecordingNotif')
		props.closeModal()
	}

	return (
		<Modal closeModal={props.closeModal}>
			<Container >
				<Contents>
					<Title className="header">Logout Confirmation</Title>
					<Details className="subheader">You are currently at the meeting. Are you sure you want to log out?</Details>
				</Contents>

				<Options>
					<Button className="button default" onClick={Cancel}>No</Button>
					<Button className="button primary" onClick={Yes}>Yes</Button>
				</Options>
			</Container>
		</Modal>
	)
}


const Container = styled.div`
	padding: 12px;
	display: flex;
	flex-direction: column;
	align-items: center;
    max-width: 420px;
`

const Contents = styled.div`
	display: flex;
	flex-direction: column;
	padding: 20px;
`

const Title = styled.div`
	font-weight: ${props => props.theme.textBold};
	font-size: ${props => props.theme.textLG};
`

const Details = styled.div`
	font-weight: ${props => props.theme.textRegular};
	font-size: ${props => props.theme.textMD};
	padding-top: 10px;
`

const Options = styled.div`
	display: flex;
	width: 70%;
`

const Button = styled.div`
	cursor: pointer;
	display: flex;
	justify-content: center;
	flex: 1;
	padding: 14px;
	margin: 4px;
`

export default ConfirmLogout