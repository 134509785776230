import React, { PureComponent } from 'react'
import styled from 'styled-components'

import LoopApi from '/helpers/LoopApi'

export default class NotFound extends PureComponent {
	constructor(props) {
		super(props)
		this.createMeeting = this.createMeeting.bind(this)
		this.state = {
			errorText: null,
		}
	}

    // componentDidMount() {
    //     window.location.href = '/login'
    // }

	async createMeeting() {
		try {
			await LoopApi(null, 'CreateMeeting', {
				name: this.props.match.params.meetingName,
				is_public: true,
			})
			this.props.JoinedMeeting()
		} catch (e) {
			console.error(e)
			this.setState({ errorText: e })
		}
	}
	render() {
		return (
			<Container className="topbar rounded inner">
				<Text>There's no room here!</Text>
				<Subtitle>Do you want to create one?</Subtitle>
				<SubmitButton 
					className="button primary"
					onClick={() => window.location.href = '/create-or-join'}>Go Back</SubmitButton>
				{this.state.errorText && (
					<div>{this.state.errorText}</div>
				)}
			</Container>
		)
	}
}

const SubmitButton = styled.button`
	height: 36px;
	border: none;
	padding: 0px !important;
	margin-top: 12px;
	font-size: 14px;
	cursor: pointer;
	transition: box-shadow 0.3s ease-out;

	&:disabled {
		pointer-events: none;
		opacity: .5;
	}

	&:hover {
		box-shadow: ${props => props.theme.shadows.light};
	}
`

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: stretch;
	padding: 36px;
	border-radius: 4px;
	box-shadow: ${props => props.theme.shadows.newlight};
	width: 352px;
	z-index: 3;
`

const Text = styled.div`
	text-align: center;
	font-weight: 500;
	font-size: 16px;
`

const Subtitle = styled.div`
	margin: 15px 0;
	text-align: center;
`


// const Text = styled.div`
// 	text-align: center;
// 	font-size: 20px;
// 	margin-top: 24px;
// 	margin-bottom: 8px;
// 	font-weight: 500;
// `
