import React from 'react'
import styled from 'styled-components'
import Reflux from 'reflux'

import AllWidgets from '../../../loop-widgets'
import LoopApi from '/helpers/LoopApi'
import { MainStore, WidgetActions } from '/stores/MainStore'
import { ModalActions } from '/stores/ModalStore'

export default class TabWidgets extends Reflux.Component {
    constructor(props) {
        super(props)

        this.store = MainStore
        this.storeKeys = [
            'widgets',
            'currentWidget',
            'localCurrentWidget',
            'meetingName',
            'db_meeting',
            'locallyHiddenSync'
        ]
        this.state = {
			db_meeting: {
				settings: {
					expandedWidgets: [],
					widgetOrder: [],
                    widget_display_method: 'Keynote'
				},
			},
        }

        this._setActiveWidget = this._setActiveWidget.bind(this)

    }

    _setActiveWidget(name) {
		// LoopApi('main', 'SetCurrentWidget', { currentWidget: name })
        const localCurrentWidget = {
            ...this.state.localCurrentWidget,
            [this.state.meetingName]: name
        }
		WidgetActions.SetCurrentWidget({ currentWidget: name })
        WidgetActions.SetLocalCurrentWidget({ localCurrentWidget })

        // LOCAL STATE
        localStorage.setItem("currentWidget", JSON.stringify(localCurrentWidget))
	}

    render() {
        let widgetList = []
		let currentIsMinimized = false
        const { db_meeting: { settings: { widget_display_method } } } = this.state

		const enabledWidgets = process.env.REACT_APP_ENABLED_WIDGETS || 'asana,clickup,dropbox,files,gists,github,googledrive,hubspot,images,links,notes,pdfpresenter,scheduler,slack,tictactoe,todo,transcription,twitch,youtube,whiteboard'
		const enabledWidgetsArray = enabledWidgets.split(",").map((item) => { return item.trim()}).filter((item) => !!item)
		
        const currentWidet = this.state.localCurrentWidget && this.state.localCurrentWidget[this.state.meetingName] ? this.state.localCurrentWidget[this.state.meetingName] : this.state.currentWidget
		let curWidget = currentWidet
		if(curWidget && !(enabledWidgetsArray || []).includes(currentWidet) && (enabledWidgetsArray || []).length > 0) curWidget = enabledWidgetsArray[0]
		if(curWidget && !(enabledWidgetsArray || []).includes(currentWidet) && !!!(enabledWidgetsArray || []).length) curWidget = null

		if (this.state.db_meeting && this.state.db_meeting.settings) {
			let { widgetOrder, minimizedWidgets } = this.state.db_meeting.settings
			minimizedWidgets = minimizedWidgets || []
			currentIsMinimized = minimizedWidgets.includes(currentWidet)

			widgetOrder = (widgetOrder || []).filter((w) => !!this.state.widgets[w])

			const missing = Object.keys(this.state.widgets).filter(
				(w) => !widgetOrder.includes(w)
			)
			const order = [...(widgetOrder || []), ...missing]
			widgetList =this.state.widgets &&
				order
                .filter(
                    (w) => AllWidgets[w] || AllWidgets[this.state.widgets[w]._component]
                )
                .filter((el) => {
                    return enabledWidgetsArray.indexOf(el) >= 0; 
                })
                .filter((el) => ((this.state.locallyHiddenSync && this.state.locallyHiddenSync[this.state.meetingName]) || []).indexOf(el) === -1)
                .map((w, ii) => {
                    const { icon, displayName } = AllWidgets[
                        this.props.componentOverride || w
                    ].widgetConfig

                    const current_widget = currentWidet === w && !currentIsMinimized
                    return <TabWidgetItem
                        className={`button topbar ${current_widget ? 'border-primary': 'border-topbar'}`}
                        minimized={minimizedWidgets.includes(w)}
                        current_widget={current_widget}
                        key={w}
                        onClick={() => this._setActiveWidget(w)}
                    >
                        <div className="item"><img src={icon} alt={displayName} /> <span className="item-title">{displayName}</span></div>
                    </TabWidgetItem>
                })
        }

        return (
            <TabWidgetContainer keynote={widget_display_method === 'Keynote'}>
                <WidgetListContainer>
                    {widgetList}
                </WidgetListContainer>
                <AddSync className="button default" onClick={() => ModalActions.SetModal('AddWidget')}>
                    Add Sync
                </AddSync>
            </TabWidgetContainer>
        )
    }
}

const AddSync = styled.div`
    padding: 10px !important;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    min-width: 120px;
    max-height: 42px;
    margin-left: 15px;
`

const TabWidgetItem = styled.div`
    border: 2px solid;
    min-width: 150px;
    padding: 10px !important;
    cursor: pointer;

    .item {
        display: flex;
        align-items: center;
        justify-content: center;
        
        img {
            width: 18px;
            height: 18px;
        }

        .item-title {
            font-size: 14px;
            margin-left: 6px;
            font-weight: 500;
        }
    }
`;

const WidgetListContainer = styled.div`
    display: flex;
    flex: 1;
    overflow: auto;
    padding-bottom: 10px;

    > ${TabWidgetItem}:not(:last-child) {
        margin-right: 15px;
    }
`

const TabWidgetContainer = styled.div`
    display: ${props => props.keynote ? 'none' : 'flex'};
    max-width: 100%;
    padding: 15px 15px 10px;
`
