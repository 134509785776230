import React from 'react'
import ReactDOM from 'react-dom'
import Router from './Router'
import App from './App'
// import { AppContainer } from 'react-hot-loader'
require('hacktimer')
import * as Sentry from '@sentry/react'
import {Integrations} from '@sentry/tracing'
import io from 'socket.io-client'
const socket = io(process.env.REACT_APP_WS_URL || 'wss://localhost:8000')
import ReconnectingWebSocket from 'reconnecting-websocket'
import ShareDbClient from 'sharedb/lib/client'
import richText from 'rich-text'
import {constant} from 'lodash'
ShareDbClient.types.register(richText.type)
const Connection = ShareDbClient.Connection
const shareDbsocket = new ReconnectingWebSocket(
  process.env.REACT_APP_SHAREDB_SOCKET_URL || 'wss://localhost:8080',
)
const connection = new Connection(shareDbsocket)

window.socket = socket
window.shareDbConnection = connection

let is_electron = false
var userAgent = navigator.userAgent.toLowerCase()
if (userAgent.indexOf(' electron/') > -1) {
  is_electron = true
}
const BrowserRouter = require('react-router-dom')['BrowserRouter']

Sentry.init({
  dsn: 'https://0375d6d11c234e3f9b5ec4ebd76dbc69@o1069839.ingest.sentry.io/6065129',
  release: process.env.REACT_APP_VERSION || '0.0.0.0',
  environment: process.env.REACT_APP_ENV_NAME || 'Unset',
  integrations: [new Integrations.BrowserTracing()],
  // tracesSampleRate: 0.2,
  attachStacktrace: true,
  beforeSend(event, hint) {
    // Check if it is an exception, and if so, show the report dialog
    if (event.exception) {
      if (process.env.REACT_APP_ENV_NAME !== 'fe-production') {
        // Sentry.showReportDialog({eventId: event.event_id})
      } else {
        console.log('Sentry report dialog has been disabled.')
      }
      //   Sentry.showReportDialog({ eventId: event.event_id });
    }
    return event
  },
  ignoreErrors: ['ResizeObserver loop limit exceeded'],
})

const renderMethod = ReactDOM.render

const rootEl = document.getElementById('root')

window.onunload = function () {
  sessionStorage.removeItem('roomsAllowed')
  sessionStorage.removeItem('safariPermissionAllowed')
}

renderMethod(
  // <AppContainer>
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  /* </AppContainer>, */
  rootEl,
)
