import React, { Component } from 'react'
import { GlobalState } from 'reflux'
import styled from 'styled-components'
import Modal from '/components/Modalv2'
import { ModalActions } from '../../../stores/ModalStore'

export default class UncheckPublicAndKnocking extends Component {
	constructor(props) {
		super(props)

		this.Cancel = this.Cancel.bind(this)
		this.Allow = this.Allow.bind(this)
	}

	Cancel() {
		this.props.closeModal()
		const prevState = GlobalState.modal.prevState
		ModalActions.SetParams({prevState: 
			{
				...prevState, 
				uncheckPKAccepted: false,
				settings: {
					...prevState.settings,
					is_public: true,
					allow_knocking: true
				}
			}
		})
        ModalActions.SetModal('ChangeSettings')
	}

	Allow() {
		GlobalState.modal.cb && GlobalState.modal.cb()
        this.props.closeModal()
	}

	render() {
		return (
			<Modal closeModal={this.props.closeModal}>
				<Container >
					<Contents>
						<Title className="header">Set Room to Private</Title>
						<Details className="subheader">Warning: Are you sure you want to continue with this settings?<br/>
                        This will <span style={{ fontWeight: 700 }}>NOT</span> allow new users to enter/join the room.</Details>
					</Contents>

					<Options>
						<Button className="button default" onClick={this.Cancel}>Cancel</Button>
						<Button className="button primary" onClick={this.Allow}>Confirm</Button>
					</Options>
				</Container>
			</Modal>
		)
	}
}

const Container = styled.div`
	padding: 12px;
	display: flex;
	flex-direction: column;
	align-items: center;
`

const Contents = styled.div`
	display: flex;
	flex-direction: column;
	padding: 20px;
`

const Title = styled.div`
	font-weight: ${props => props.theme.textBold};
	font-size: ${props => props.theme.textLG};
`

const Details = styled.div`
	font-weight: ${props => props.theme.textRegular};
	font-size: ${props => props.theme.textMD};
	padding-top: 10px;
`

const Options = styled.div`
	display: flex;
	width: 70%;
`

const Button = styled.div`
	cursor: pointer;
	display: flex;
	justify-content: center;
	flex: 1;
	padding: 14px;
	margin: 4px;
`
